import React, { useState, createContext } from "react";
import { produce } from "immer";
import Sjaars from "./sjaars/sjaars";
import KlussenEerstejaars from "./klussen-eerstejaars/klussenEerstejaars";
import KlussenIndeling from "./klussen-indeling/klussenIndeling";
import KookKlussenEerstejaars from "./kookklussen-eerstejaars/kookKlussenEerstejaars";

import { useCollectionData } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import skadiColors from "./../../../skadiColors";
import Moment from "moment";

import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

Moment.locale("nl");

const AntTabs = withStyles({
  root: {
    borderBottom: "2px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: skadiColors.primary,
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: skadiColors.primary,
      opacity: 1,
    },
    "&$selected": {
      color: skadiColors.primary,
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: skadiColors.primary,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(1),
  },
}));

const createMonths = (year) => {
  console.log("create months for " + year);
  const months = [];
  const dateStart = Moment(year + "0901", "YYYYMMDD");
  const dateEnd = Moment(year + "0901", "YYYYMMDD").add(14, "month");
  while (dateEnd.diff(dateStart, "months") >= 0) {
    months.push({
      value: Moment(dateStart.format("YYYY/MM/DD HH:mm:ss")),
      text: dateStart.format("MMMM YYYY"),
    });
    dateStart.add(1, "month");
  }
  return months;
};

const initialState = {
  selectedKlus: null,
  selectedKookKlus: null,
  year: "-2021",
  months: createMonths(2021),
};

export const StateContext = createContext({});

const KlussenComponent = (props) => {
  const classes = useStyles();

  const [state, setState] = useState(initialState);
  const [value, setValue] = useState(0);

  const [sjaars, loading, error] = useCollectionData(
    firebase.firestore().collection("eerstejaars" + state.year),
    {
      idField: "id",
    }
  );

  const [indeling, loading2] = useCollectionData(
    firebase.firestore().collection("klussen-indeling" + state.year),
    {
      idField: "id",
    }
  );

  const [klussen, loading3] = useCollectionData(
    firebase.firestore().collection("klussen-eerstejaars" + state.year),
    { idField: "id" }
  );

  const [kookindeling, kookloading2] = useCollectionData(
    firebase.firestore().collection("kook-indeling" + state.year),
    {
      idField: "id",
    }
  );

  const [kookklussen, kookloading3] = useCollectionData(
    firebase.firestore().collection("kook-eerstejaars" + state.year),
    {
      idField: "id",
    }
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setKlus = (value) => {
    const found = value ? klussen.find((klus) => klus.id === value.id) : null;
    const newState = produce(state, (draftstate) => {
      draftstate.selectedKlus = found;
    });
    setState(newState);
  };

  const setKookKlus = (value) => {
    const found = value
      ? kookklussen.find((klus) => klus.id === value.id)
      : null;
    const newState = produce(state, (draftstate) => {
      draftstate.selectedKookKlus = found;
    });
    setState(newState);
  };

  const setYear = (value) => {
    const newYear = value ? parseInt(value.split("-").pop()) : 2019;

    const newState = produce(state, (draftstate) => {
      draftstate.year = value;
      draftstate.months = createMonths(newYear);
    });
    setState(newState);
  };

  const context = {
    state: state,
    actions: {
      setKlus: setKlus,
      setKookKlus: setKookKlus,
      setTab: handleChange,
      setYear: setYear,
    },
  };

  const KlussenYear = () => {
    const years = [
      // { value: "", text: "2019-2020" },
      // { value: "-2020", text: "2020-2021" },
      { value: "-2021", text: "2021-2022" },
      { value: "-2022", text: "2022-2023" },
      { value: "-2023", text: "2023-2024" },
      { value: "-2024", text: "2024-2025" },
    ];

    const handleYear = (event, newYear) => {
      if (newYear !== null) {
        context.actions.setYear(newYear);
      }
    };

    return (
      <Grid container justify="space-between">
        <Grid item>
          <h2>
            Eerstejaars- & Kookklussen voor het seizoen{" "}
            {years.find((year) => year.value === context.state.year).text}
          </h2>
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            value={context.state.year}
            exclusive
            onChange={handleYear}
            aria-label="text alignment"
          >
            {years.map((year, i) => {
              return (
                <ToggleButton key={i} value={year.value}>
                  {year.text}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    );
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {children}
      </Typography>
    );
  };

  const SjaarsTab = () => (
    <Sjaars value={sjaars} loading={loading} error={error} />
  );

  const KlussenTab = () =>
    !loading && !loading2 && !loading3 ? (
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={context.state.selectedKlus ? 6 : 12}
          lg={context.state.selectedKlus ? 6 : 12}
        >
          <KlussenEerstejaars
            sjaars={sjaars}
            indeling={indeling}
            klussen={klussen}
          />
        </Grid>

        {context.state.selectedKlus ? (
          <Grid item xs={12} sm={6} lg={6}>
            <KlussenIndeling
              sjaars={sjaars}
              indeling={indeling}
              klussenEerstejaars={klussen}
              type={"klussen"}
              selected={context.state.selectedKlus}
              setSelected={context.actions.setKlus}
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    ) : (
      <></>
    );

  const KookKlussenTab = () =>
    !loading && !kookloading2 && !kookloading3 ? (
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={state.selectedKookKlus ? 6 : 12}
          lg={state.selectedKookKlus ? 6 : 12}
        >
          <KookKlussenEerstejaars
            sjaars={sjaars}
            indeling={kookindeling}
            klussen={kookklussen}
          />
        </Grid>

        {state.selectedKookKlus ? (
          <Grid item xs={12} sm={6} lg={6}>
            <KlussenIndeling
              sjaars={sjaars}
              indeling={kookindeling}
              klussenEerstejaars={kookklussen}
              type={"kook"}
              selected={context.state.selectedKookKlus}
              setSelected={context.actions.setKookKlus}
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    ) : (
      <></>
    );

  return (
    <StateContext.Provider value={context}>
      <div className={classes.root}>
        <KlussenYear />
        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
          <AntTab label="Eerstejaars" />
          <AntTab label="Eerstejaarsklussen" />
          <AntTab label="Kookklussen" />
        </AntTabs>
        <Typography className={classes.padding} />
      </div>
      <TabPanel value={value} index={0}>
        <SjaarsTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <KlussenTab />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <KookKlussenTab />
      </TabPanel>
    </StateContext.Provider>
  );
};

export default KlussenComponent;
