import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import { withFirebase } from "../../../components/firebase";

const useStyles = makeStyles(theme => ({
  button: {
    color: "white",
    width: "100%"
  }
}));

const SignOutButton = ({ firebase, display }) => {
  const classes = useStyles();
  return (
    <Box display={display}>
      <Button
        color="primary"
        variant="contained"
        className={classes.button}
        onClick={firebase.doSignOut}
      >
        Sign Out
      </Button>
    </Box>
  );
};

export default withFirebase(SignOutButton);
