import React, { useState, forwardRef, useContext } from "react";
import SkadiModal from "../../../../components/modal/skadiModal";
import firebase from "firebase";
import produce from "immer";
import Moment from "moment";
import MaterialTable from "material-table";

import { StateContext } from "../index";
import { GetSubCollection } from "../helpers";

import { makeStyles } from "@material-ui/core/styles";
import AddBox from "@material-ui/icons/AddBox";
import Add from "@material-ui/icons/Add";
import CompareArrows from "@material-ui/icons/CompareArrows";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Restaurant from "@material-ui/icons/Restaurant";
import Build from "@material-ui/icons/Build";

import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";

import { useDocumentData } from "react-firebase-hooks/firestore";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      minWidth: 1000,
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    "& div": {
      width: "100%",
    },
  },
  formControl: {
    margin: theme.spacing(2, 0, 1, 0),
    minWidth: 120,
    height: 48,
  },
  checkers: {
    margin: theme.spacing(1, 0, 2, 0),
    minWidth: 120,
    height: 48,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttons: {
    marginTop: "auto",
    paddingTop: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
  },
  applybutton: {
    color: "white",
  },
  table: {
    marginTop: 15,
  },
}));

export const SkadiInput = ({ r, handleChange, fields }) =>
  fields.map((x) => (
    <TextField
      key={x.field}
      label={x.label ? x.label : x.field}
      value={r[x.field] ? r[x.field] : ""}
      onChange={(e) => handleChange(e, x.field)}
      margin="normal"
    />
  ));

const SjaarsForm = ({ sjaars, handleClose, newsjaars, log }) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [r, setSjaars] = useState(sjaars);
  const handleChange = (e, n) => setSjaars({ ...r, [n]: e.target.value });
  const context = useContext(StateContext);

  const saveSjaars = () => {
    if (newsjaars) {
      firebase
        .firestore()
        .collection("eerstejaars" + context.state.year)
        .doc()
        .set({
          voornaam: r.voornaam,
          tussenvoegsel: r.tussenvoegsel,
          achternaam: r.achternaam,
          "e-mail": r["e-mail"],
          telefoon: r.telefoon,
          autoAssign: r.autoAssign,
        })
        .then(handleClose());
    } else {
      firebase
        .firestore()
        .collection("eerstejaars" + context.state.year)
        .doc(r.id)
        .update({
          voornaam: r.voornaam,
          tussenvoegsel: r.tussenvoegsel,
          achternaam: r.achternaam,
          "e-mail": r["e-mail"],
          telefoon: r.telefoon,
          autoAssign: r.autoAssign,
        })
        .then(handleClose());
    }
  };

  return (
    <SkadiModal
      open={true}
      onClose={handleClose}
      title={newsjaars ? "Eerstejaars toevoegen" : "Eerstejaars bewerken"}
      onEnter={saveSjaars}
      error={error}
      setError={setError}
    >
      <div className={classes.root}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4} lg={4}>
            <form className={classes.form}>
              <SkadiInput
                r={r}
                handleChange={handleChange}
                fields={[
                  { label: "Voornaam", field: "voornaam" },
                  { label: "Tussenvoegsel", field: "tussenvoegsel" },
                  { label: "Achternaam", field: "achternaam" },
                  { label: "E-mail", field: "e-mail" },
                  { field: "telefoon", label: "Telefoon" },
                ]}
              />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={r.autoAssign}
                      onChange={(e) => {
                        const newState = produce(r, (draftstate) => {
                          draftstate.autoAssign = e.target.checked;
                        });
                        setSjaars(newState);
                      }}
                      value="autoAssign"
                      color="primary"
                    />
                  }
                  label="Automatisch indelen"
                />
              </FormGroup>
            </form>
          </Grid>
          <Grid item xs={12} sm={8} lg={8}>
            <MaterialTable
              className={classes.table}
              // isLoading={loading}
              onRowClick={(event, rowData) => {
                if (rowData.klus && rowData.type !== "Delete") {
                  context.actions.setTab(event, rowData.kook ? 2 : 1);
                  context.actions.setKlus({
                    id: rowData.klus,
                    naam: rowData.naam,
                  });
                }
              }}
              columns={[
                {
                  title: "Klus",
                  field: "kook",
                  render: (row) => (row.kook ? <Restaurant /> : <Build />),
                },
                { title: "Info", field: "content" },
                {
                  title: "Datum",
                  field: "created",
                  type: "datetime",
                  defaultSort: "desc",
                  render: (row) =>
                    Moment(row.created.seconds * 1000).format("lll"),
                },
                {
                  title: "Type",
                  field: "type",
                  render: (row) =>
                    row.type === "add" ? (
                      <Add />
                    ) : row.type === "delete" ? (
                      <DeleteOutline />
                    ) : row.type === "change" ? (
                      <CompareArrows />
                    ) : (
                      <Remove />
                    ),
                },
              ]}
              data={log}
              options={{
                pageSize: 7,
                pageSizeOptions: [7],
                emptyRowsWhenPaging: false,
                toolbar: false,
              }}
              icons={tableIcons}
              localization={{
                toolbar: { searchPlaceholder: "Zoeken" },
              }}
            />
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          <Button color="primary" onClick={handleClose}>
            Annuleren
          </Button>
          <Button
            className={classes.applybutton}
            color="primary"
            variant="contained"
            onClick={saveSjaars}
          >
            Opslaan
          </Button>
        </div>{" "}
      </div>
    </SkadiModal>
  );
};

const SjaarsModal = ({ id, handleClose }) => {
  const context = useContext(StateContext);

  const [data, loading, error] = useDocumentData(
    firebase
      .firestore()
      .collection("eerstejaars" + context.state.year)
      .doc(id),
    {
      idField: "id",
    }
  );

  const [log, loading2, error2] = GetSubCollection(id, context.state.year);

  if ((loading, loading2)) return <></>;
  else if (error) return error;
  else if (error2) return error2;
  else {
    return (
      <SjaarsForm
        sjaars={
          id !== "false"
            ? data
            : {
                voornaam: "",
                tussenvoegsel: "",
                achternaam: "",
                "e-mail": "",
                telefoon: "",
                id: null,
                klussen: 0,
              }
        }
        newsjaars={id === "false"}
        handleClose={handleClose}
        log={log}
      />
    );
  }
};

const EditSjaars = ({ id, setId }) => {
  const open = id != null;
  const handleClose = () => {
    if (open) setId(null);
  };
  if (!open) return <></>;
  else return <SjaarsModal id={id} handleClose={handleClose} />;
};

export default EditSjaars;
