import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import logo from "logo.png";
import background from "background.jpg";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  image: {
    // backgroundImage:
    //   "url(https://source.unsplash.com/collection/8795314/1600x900)",
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1)
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    "& .MuiButton-label": {
      color: "white"
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const LoginLayout = props => {
  const classes = useStyles();
  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src={logo} alt="logo" width="70px" className={classes.avatar} />
          <Typography component="h1" variant="h5">
            Inloggen
          </Typography>
          <form className={classes.form} noValidate onSubmit={props.onSubmit}>
            {props.children}
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default LoginLayout;
