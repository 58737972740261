import React, { useState } from "react";
import { produce } from "immer";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import DrawerContainer from "./drawer";
import Toolbar from "./toolbar";
import Layout, { structure } from "./Layout";

const initialState = {
  isSidebarOpened: false,
  isPermanent: true,
  currentPage: ""
};

export const StateContext = React.createContext({});

const Dashboard = () => {
  const [state, setState] = useState(initialState);

  const toggleSidebar = () => {
    const newState = produce(state, draftstate => {
      draftstate.isSidebarOpened = !state.isSidebarOpened;
    });
    setState(newState);
  };

  const togglePermanent = value => {
    const newState = produce(state, draftstate => {
      draftstate.isPermanent = value;
    });
    setState(newState);
  };

  const setPage = value => {
    const newState = produce(state, draftstate => {
      draftstate.currentPage = value;
    });
    setState(newState);
  };

  const context = {
    state: state,
    actions: {
      toggleSidebar: toggleSidebar,
      togglePermanent: togglePermanent,
      setPage: setPage
    }
  };

  // const FBContext = useContext(FirebaseContext);

  // const [claim, setClaim] = useState({});
  const claim = {};

  // useEffect(() => {
  //   const unsubscribe = FBContext.doClaimCheck(structure, data => {
  //     setClaim(data);
  //   });
  //   return () => unsubscribe();
  // }, [FBContext]);

  const RenderRoute = ({ component: Component, access, label, ...rest }) => (
    <Route
      {...rest}
      render={props => {
        setPage(label); // This gives a unmounted component warning, should be fixed by moving it to a useeffect in the components
        return <Component {...props} />;
        // if (claim[access] || access === undefined) {
        //   return <Component {...props} />;
        // } else {
        //   return <Error />;
        // }
      }}
    />
  );

  return (
    <StateContext.Provider value={context}>
      <BrowserRouter>
        <React.Fragment>
          <Layout
            drawer={<DrawerContainer structure={structure} claim={claim} />}
            toolbar={<Toolbar />}
          >
            <Switch>
              {structure.map(page => (
                <RenderRoute
                  exact
                  key={page.id}
                  access={page.claim}
                  path={page.link}
                  label={page.label}
                  component={page.component}
                />
              ))}
              <Redirect to="/overview" />
            </Switch>
          </Layout>
        </React.Fragment>
      </BrowserRouter>
    </StateContext.Provider>
  );
};

export default Dashboard;
