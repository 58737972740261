import React, { useContext } from "react";
import classnames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Typography } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";

import SignOutButton from "./signout";
import { StateContext } from "../index.js";

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  }
}));

const Toolbar = props => {
  const classes = useStyles();
  const context = useContext(StateContext);

  return (
    <>
      {!context.state.isPermanent ? (
        <IconButton
          color="inherit"
          onClick={() => context.actions.toggleSidebar()}
          className={classes.headerMenuButton}
        >
          <MenuIcon
            classes={{
              root: classnames(classes.headerIcon, classes.headerIconCollapse)
            }}
          />
        </IconButton>
      ) : null}
      <Typography variant="h6" weight="medium">
        {context.state.currentPage}
      </Typography>
      <div className={classes.grow} />

      <SignOutButton display={{ xs: "none", md: "block" }} />
    </>
  );
};

export default Toolbar;
