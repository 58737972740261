import React, { useState, useEffect } from "react";
import { treeUtil } from "react-d3-tree";
import { produce } from "immer";

import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";

import skadiColors from "./../../../skadiColors";
import DrawerComponent from "./drawer";
import TreeFilter from "./treeFilter";

import firebase from "firebase";

import "react-treelist/build/css/index.css";

const useStyles = makeStyles((theme) => ({
  treeWrapper: {
    width: `calc(100% + ${theme.spacing(3) * 2}px)`,
    height: `calc(100vh - 64px)`,
    marginLeft: -theme.spacing(3),
    marginTop: -theme.spacing(3),
    marginBottom: -theme.spacing(3),
  },
  paddingLeft: {
    paddingLeft: theme.spacing(1),
  },
}));

const initialState = {
  selected: null,
  drawer: false,
  type: null,
  treeReload: new Date(),
  filter: null,
};

export const MeerjarenContext = React.createContext({});

const db = firebase.firestore();

const MeerjarenplanComponent = () => {
  const classes = useStyles();

  const GetMeerjarenTree = (treeReload) => {
    // initialize our default state
    const [tree, setTree] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    // when the attribute changes (including mount)
    // subscribe to the recipe document and update
    // our state when it changes
    useEffect(() => {
      let isCurrent = true;
      const levels = ["werkstroom", "programma", "project"];
      let queries = levels.map((level) => {
        return db.collectionGroup(level).get();
      });

      Promise.all(queries)
        .then((querySnapshots) => {
          return querySnapshots
            .map((qs) => qs.docs)
            .reduce((acc, docs) => [...acc, ...docs]);
        })
        .then(
          (matchingArticleRefs) => {
            let logs = [];
            matchingArticleRefs.forEach((doc) => {
              // if (doc.data().verantwoordelijke === "CvM") {
              logs.push({
                parent: doc.data().parent ? doc.data().parent.id : null,
                child: doc.id,
                attributes: {
                  naam: doc.data().naam,
                  verantwoordelijke: doc.data().verantwoordelijke,
                  reference: doc.ref.path,
                  type: doc.ref.parent.id,
                },
              });
              // }
            });
            logs = treeUtil.generateHierarchy(logs);
            if (isCurrent) {
              setTree(logs);
              setLoading(false);
            }
          },
          (err) => {
            setError(err);
          }
        );
      return () => {
        isCurrent = false;
      };
    }, [treeReload]);
    return [tree, loading, error];
  };

  const [state, setState] = useState(initialState);

  const [tree, loading] = GetMeerjarenTree(state.treeReload);

  const [expanded, setExpanded] = useState(["J6ulwW2WnIX9KiEU60Gx"]);
  const [selected, setSelected] = useState([]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const handleDrawerFromDrawer = (refrence, type) => {
    const newState = produce(state, (draftstate) => {
      draftstate.selected = refrence;
      draftstate.type = type;
      draftstate.drawer = true;
    });
    setState(newState);
  };

  const handleDrawerOpen = (nodeData) => {
    if (nodeData.attributes) {
      const newState = produce(state, (draftstate) => {
        draftstate.selected = nodeData.attributes.reference;
        draftstate.type = nodeData.attributes.type;
        draftstate.drawer = true;
      });
      setState(newState);
    }
    // db.doc(
    //   nodeData.attributes
    //     ? nodeData.attributes.reference
    //     : "/meerjarenplan/J6ulwW2WnIX9KiEU60Gx"
    // )
    //   .get()
    //   .then((doc) => console.log(doc));
  };

  const handleDrawerClose = () => {
    const newState = produce(state, (draftstate) => {
      draftstate.drawer = false;
    });
    setState(newState);
  };

  const handleTreeReload = () => {
    const newState = produce(state, (draftstate) => {
      draftstate.treeReload = new Date();
    });
    setState(newState);
  };

  const handleTreeFilterChange = (value) => {
    const newState = produce(state, (draftstate) => {
      draftstate.filter = value;
    });
    setState(newState);
  };

  const context = {
    state: state,
    actions: {
      handleDrawerOpen: handleDrawerOpen,
      handleDrawerClose: handleDrawerClose,
      handleTreeReload: handleTreeReload,
      handleDrawerFromDrawer: handleDrawerFromDrawer,
    },
  };

  if (loading) {
    return <div>loading</div>;
  }

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.name}
      nodeId={nodes.name}
      label={nodes.attributes ? nodes.attributes.naam : "2025 plan"}
      onLabelClick={(event) => {
        console.log(expanded, nodes.name);
        if (expanded.includes(nodes.name)) {
          event.preventDefault();
        }
        handleDrawerOpen(nodes);
      }}
    >
      {Array.isArray(nodes._children)
        ? nodes._children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <MeerjarenContext.Provider value={context}>
      <div className={classes.treeWrapper}>
        <TreeFilter></TreeFilter>
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded}
          selected={selected}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
        >
          {renderTree(tree[0])}
        </TreeView>
        {/* <Tree
          data={tree}
          styles={treeStyles}
          orientation="vertical"
          translate={{ x: 200, y: 50 }}
          nodeSvgShape={svgCircle}
          pathFunc="step"
          textLayout={treeText}
          zoom={0.8}
          initialDepth={1}
          separation={{ siblings: 2, nonSiblings: 2 }}
          allowForeignObjects
          nodeLabelComponent={{
            render: <NodeLabel className="labelInSvg" />,
            foreignObjectWrapper: {
              y: -50,
              x: 30,
              width: 200,
              height: 150,
            },
          }}
        /> */}
        {state.selected ? <DrawerComponent /> : null}
      </div>
    </MeerjarenContext.Provider>
  );
};

export default MeerjarenplanComponent;
