import React from "react";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { makeStyles } from "@material-ui/styles";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: "none",
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderLeftWidth: theme.spacing(0.25),
    borderLeftColor: theme.palette.background.main,
    borderLeftStyle: "solid",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.light,
      borderLeftColor: theme.palette.primary.main
    }
  },
  bottom: {
    position: "absolute",
    bottom: 0
  },
  active: {
    background: theme.palette.background.light,
    color: theme.palette.primary.main,
    borderLeftWidth: theme.spacing(0.25),
    borderLeftColor: theme.palette.primary.main,
    borderLeftStyle: "solid"
  },
  linkIcon: {
    marginRight: theme.spacing(1),
    color: "white",
    transition: theme.transitions.create("color"),
    width: 24,
    minWidth: 24,
    display: "flex",
    justifyContent: "center"
  },
  linkIconActive: {
    color: theme.palette.primary.main
  },
  linkText: {
    padding: 0,
    color: theme.palette.text.secondary + "CC",
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 16
  },
  linkTextActive: {
    color: theme.palette.primary.main
  },
  linkTextHidden: {
    opacity: 0
  },
  navlink: {
    textDecoration: "none",
    color: "inherit"
  }
}));

const WithTooltip = ({ isSidebarOpened, label, children }) =>
  !isSidebarOpened ? (
    <Tooltip title={label} enterDelay={200} placement="right">
      {children}
    </Tooltip>
  ) : (
    children
  );

const DrawerLink = props => {
  const classes = useStyles();

  const isLinkActive = props.link && props.location.pathname === props.link;

  return (
    <NavLink to={props.link} className={classes.navlink}>
      <WithTooltip isSidebarOpened={props.isSidebarOpened} label={props.label}>
        <ListItem
          button
          className={classnames(classes.link, {
            [classes.active]: isLinkActive,
            [classes.bottom]: props.position === "bottom" && props.isPermanent
          })}
          disableRipple
        >
          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: isLinkActive
            })}
          >
            {props.icon}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classnames(classes.linkText, {
                [classes.linkTextActive]: isLinkActive,
                [classes.linkTextHidden]: !props.isSidebarOpened
              })
            }}
            primary={props.label}
          />
        </ListItem>
      </WithTooltip>
    </NavLink>
  );
};

export default DrawerLink;
