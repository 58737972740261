import { createTheme } from "@material-ui/core/styles";
import colors from "./skadiColors";

const skadiTheme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    background: {
      main: colors.grey1,
      light: colors.grey2,
    },
    grey: {
      800: colors.grey4,
    },
  },
  status: {
    danger: colors.warn,
  },
  green: colors.green,
});

export default skadiTheme;
