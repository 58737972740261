import firebase from "firebase";

/**
 * Call the 'recursiveDelete' callable function with a path to initiate
 * a server-side delete.
 */
export const deleteAtPath = (path) => {
  var deleteFn = firebase.functions().httpsCallable("recursiveDelete");
  return deleteFn({ path: path });
  // .then(function (result) {
  //   console.log("Delete success: " + JSON.stringify(result));
  // })
  // .catch(function (err) {
  //   console.log("Delete failed, see console,");
  //   console.warn(err);
  // });
};
