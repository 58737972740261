import React, { useContext, useState, useEffect } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import MaterialTable from "material-table";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import firebase from "firebase";
import { tableIcons } from "./../klussen/klussen-eerstejaars/klussenEerstejaars";
import { MeerjarenContext } from "./index";
import { ErrorBarTimeout } from "./../../../components/snackbar";
import { deleteAtPath } from "./../../../components/functionHelpers";
import { verantwoordelijken } from "./verantwoordelijken";
import WerkstroomFinanceTableComponent from "./werkstroomFinanceTable";

import { GoogleSpreadsheet } from "google-spreadsheet";

// Config variables
const SPREADSHEET_ID = "1-tGYO9mDQgjQ8OBoeaTbkM6a_LevSUFYfH4X6s4MOh4";
const SHEET_ID = "337119925";
const CLIENT_EMAIL = "skadi-tools-256419@appspot.gserviceaccount.com";
const PRIVATE_KEY =
  "-----BEGIN PRIVATE KEY-----\nMIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDABR14Ty9TFXbp\nDTM3FcBfcLVtvjaDL8o74IjoWFXb/XU35Vsr42h+dihLEls7dfAaaSjFQ61KmW5V\nxDNgbv+Onq9xN2vJNgH9TwgVOMR7xhvxS+Vo1cpjNHA8TOSqb/WTL2lDS7Tx9vsT\na6XsfuBZUT1Tebo/ayqsMzY/3Y9Jho0aex3JYCTjJj10npYfeZzsjuRw+Ys1T9+o\n8KidEwV41Dem6dzVcNHduw9MoHUnG696kErxm4wqdsBe6mNzou5CrLTn7NtVxNZI\n1N/nfMwDq9VpuiPcmVj26tGpqvQ+hqadLQi88kF22SRWj3mbzwrHrD4Ibe3h0wzy\nigxeqLFTAgMBAAECggEADZgPHGEBaQYK/g1uLnHUGwL1WoiYfrRtF8zEmdZZyxI9\nhdsvQi3ToahRypweaK49H6be47Wag2krU4JpX2QG+ZzTBNaMmJkXOPdVtig9A2+P\nXjImUplYE6qMYOh8rnLwqsk00DaANTpr+gBT+yiG+ivIhgFceyLmHtIdVBYmZRO0\nRFj7GNUp/6g2ZxAkvclfE6DEg8vXgxQ9kTbPyCbYxn8lGUAHkhi+Spvx6foxwBgv\n8StaK+tCvww//B2nT/gCWniXyOh/sFvqsF82AkWSPE3636TBZco2MI9DUEOo4VLI\nXMnE6cQ0Nq3I1Do3TR+9TK5qOvDlRTT4mhRjTVYfIQKBgQD1YdhD36rJ2GFVthci\n4kv5DvlefFCGWLwmj2FRVKcQUQVo+i0oySdFeRKmCldLcFnQzgGj2O3v0XS7PsdX\ntR4zY/CC3RAPaHp0N0lXhriyaTS5ak4rz/Yn/nOAITpQCuQSvpHOKqKHoT4C0vJB\naGrj018m1Xt2bBIMk1Y5F8yQUQKBgQDIVCon7JmXw6wEdSH92Ix6Oc+PmU4lFsiL\n3e4dpNzVifPfE76NauW/7UeoehmUQw5OM97Ja2aGdmFc7YFDdNcb4WL2DnOiXlmu\nxCNba11aHIXCEAgrxlPZSh7b/yA5GvP66FQawqhp8gpR8J8tPRmamWLfLOOhhGF3\nBEW32dhCYwKBgQCHvNliKm8SeuTVAgZoYKBAJL1jPAdbWeiyt+vQjrBfwUHp4UJr\n5mIX9zKOsPe7HmpArESR+2UbnVUiY0boSKNtMCKScXD55QSgFxk2FqtC3hmxW5Hk\ngISdeQ8xWLAmKPAjQlHrpwADYPmpnEEwV2+RmZJwK/XA+2U8maA/st+REQKBgQCf\n3rMJy4LDn89Oe1/WIhPxkuM2+SRo6ua+4CygEap+66noc+xVFWMmqKjQJij1rhHj\njPeVjJV0Z1LKA2xuNfdY86SzMtNLV7qAg1yoH2WPfUWtmne633qY5NL9xHultwHQ\nUcqU1JXsftGHlNyjEKi3PQOvkTZT9r6Faa5ty3cPewKBgQCffSb4vZttTAYTN1uy\na3ViKylPbOJD9w18WFUounorsGr7TmHmVsu1dMn+ZAtrZkFe/UnioCjy782xO2JE\n3U2F+eZWlOG1HpdtWzSLezR7N3oAugX9xlRZbKVRKI9ZiT/X3yQgahvtV5xeDRRc\narLxVASmODdlKMBjXCTSZ632Ig==\n-----END PRIVATE KEY-----\n";

const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    minWidth: "100%",
  },
}));

const loadSpreadsheet = async () => {
  try {
    await doc.useServiceAccountAuth({
      client_email: CLIENT_EMAIL,
      private_key: PRIVATE_KEY,
    });
    // loads document properties and worksheets
    await doc.loadInfo();

    const sheet = doc.sheetsById[SHEET_ID];
    console.log(sheet.getRows());
    // const result = await sheet.addRow(row);
  } catch (e) {
    console.error("Error: ", e);
  }
};

loadSpreadsheet();

const TableComponent = ({ value }) => {
  const classes = useStyles();

  const context = useContext(MeerjarenContext);

  const [tableValue, setTableValue] = useState([]);

  useEffect(() => {
    const tableValues = [];
    value.forEach((doc) => {
      tableValues.push({
        naam: doc.naam,
        verantwoordelijke: doc.verantwoordelijke,
        id: doc.id,
        hoofddoel: doc.hoofddoel,
      });
    });
    console.log(tableValues);
    setTableValue(tableValues);
    return () => {};
  }, [value]);

  return (
    <MaterialTable
      style={{
        boxShadow: "0px 0px 0px rgba(0,0,0,0.0)",
        backgroundColor: "transparent",
      }}
      title="Programma's"
      isLoading={value === []}
      onRowClick={(event, rowData) =>
        context.actions.handleDrawerFromDrawer(
          context.state.selected + "/programma/" + rowData.id,
          "programma"
        )
      }
      columns={[
        { title: "Naam", field: "naam" },
        {
          title: "Verantwoordelijke",
          field: "verantwoordelijke",
          editComponent: (props) => (
            <FormControl className={classes.formControl}>
              <Select
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">
                  <em>Geen</em>
                </MenuItem>
                {verantwoordelijken.map((verantwoordelijke) => (
                  <MenuItem value={verantwoordelijke}>
                    {verantwoordelijke}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ),
        },
        {
          title: "Hoofddoelen",
          field: "hoofddoel",
          editComponent: (props) => (
            <TextField
              className={classes.textField}
              label="Multiline"
              multiline
              rows={4}
              value={props.value}
              onChange={(e) => props.onChange(e.target.value)}
            />
          ),
        },
      ]}
      data={tableValue}
      options={{
        headerStyle: {
          backgroundColor: "transparent",
          padding: "14px 16px 14px 16px",
        },
        pageSize: 5,
        pageSizeOptions: [5, 10],
        emptyRowsWhenPaging: false,
      }}
      icons={tableIcons}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve, reject) => {
            newData.parent = firebase.firestore().doc(context.state.selected);
            firebase
              .firestore()
              .doc(context.state.selected)
              .collection("programma")
              .add(newData)
              .then(() => {
                context.actions.handleTreeReload();
                resolve();
              })
              .catch(function(error) {
                console.error("Error adding document: ", error);
                resolve();
              });
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            delete newData.id;
            firebase
              .firestore()
              .doc(context.state.selected)
              .collection("programma")
              .doc(oldData.id)
              .update(newData)
              .then(() => resolve())
              .catch(function(error) {
                console.error("Error removing document: ", error);
                resolve();
              });
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            deleteAtPath(context.state.selected + "/programma/" + oldData.id)
              .then(() => {
                context.actions.handleTreeReload();
                resolve();
              })
              .catch(function(error) {
                console.error("Error removing document: ", error);
                resolve();
              });
          }),
      }}
      localization={{
        toolbar: { searchPlaceholder: "Zoeken" },
        body: {
          addTooltip: "Toevoegen",
        },
      }}
    />
  );
};

const WerkstroomComponent = () => {
  const context = useContext(MeerjarenContext);

  const [value, loading, error] = useCollectionData(
    firebase
      .firestore()
      .doc(context.state.selected)
      .collection("programma"),
    {
      idField: "id",
    }
  );

  if (error) {
    return <ErrorBarTimeout error={error} />;
  }

  return (
    <>
      {!loading ? <TableComponent value={value} /> : null}
      <WerkstroomFinanceTableComponent programma={context.state.selected} />
    </>
  );
};

export default WerkstroomComponent;
