import React, { useState, useEffect } from "react";
import { produce } from "immer";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import firebase from "firebase";

const useStyles = makeStyles((theme) => ({
  thickBorder: {
    borderWidth: 3,
  },
  noBorder: {
    border: "none",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const defaultRows = [
  {
    naam: "Huur Boten",
    data: [
      { "2018-2019": 0, "2019-2020": 15, doel: 20 },
      { "2018-2019": 1, "2019-2020": 10, doel: 40 },
    ],
  },
  {
    naam: "Verkoop",
    data: [
      { "2018-2019": 0, "2019-2020": 15, doel: 20 },
      { "2018-2019": 1, "2019-2020": 10, doel: 40 },
    ],
  },
];

const defaultColumns = [{ key: "Doel", name: "Doel" }];

const compare = (a, b) => {
  // Use toUpperCase() to ignore character casing
  const bandA = a.key.toUpperCase();
  const bandB = b.key.toUpperCase();

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
};

const aggregateTable = (data, columns, index) => {
  return columns.reduce((obj, v) => {
    obj[v.key] = data.reduce(
      (prev, cur) => Number(cur.data[index][v.key]) + prev,
      0
    );
    obj[v.key] = obj[v.key] || 0;
    return obj;
  }, {});
};

const WerkstroomFinanceTableComponent = ({ programma }) => {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState(defaultColumns);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .doc(programma)
      .collection("programma")
      .onSnapshot((snapshots) => {
        let data = [];
        let dataColumns = [];
        snapshots.forEach((doc) => {
          const finance = doc.data().finance;
          if (finance && finance.length !== 0) {
            console.log(doc.data());
            dataColumns.push(finance[0].data[0]);
          }
        });
        dataColumns = Object.keys(Object.assign({}, ...dataColumns)).map(
          (key) => {
            return { key: key, name: key };
          }
        );
        dataColumns.sort(compare);
        snapshots.forEach((doc) => {
          if (doc.data().finance && doc.data().finance.length !== 0) {
            const docTable = {
              data: [
                aggregateTable(doc.data().finance, dataColumns, 0),
                aggregateTable(doc.data().finance, dataColumns, 1),
              ],
              naam: doc.data().naam,
            };
            data.push(docTable);
          }
        });

        setRows(data);
        setColumns(dataColumns);
      });
    return () => {
      unsubscribe();
    };
  }, [programma]);

  return (
    <>
      <Box pl={3} py={2} className={classes.container}>
        <Typography variant="h6" component="div">
          Financieel
        </Typography>
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2} />
            {columns.map((column) => (
              <TableCell key={column.key + "-head"}>{column.name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <React.Fragment key={row.naam + "-omzet"}>
              <TableRow>
                <TableCell className={classes.noBorder}>{row.naam}</TableCell>
                <TableCell>Omzet</TableCell>
                {columns.map((column) => (
                  <TableCell key={column.key + "-omzet"}>
                    {row.data[0][column.key]}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow key={row.name + "-kosten"}>
                <TableCell className={classes.thickBorder}></TableCell>
                <TableCell className={classes.thickBorder}>Kosten</TableCell>
                {columns.map((column) => (
                  <TableCell
                    className={classes.thickBorder}
                    key={column.key + "-kosten"}
                  >
                    {row.data[1][column.key]}
                  </TableCell>
                ))}
              </TableRow>
            </React.Fragment>
          ))}
          <TableRow>
            <TableCell>
              <b>Totaal</b>
            </TableCell>
            <TableCell>
              <b>Omzet</b>
            </TableCell>
            {columns.map((column) => (
              <TableCell key={column.key + "-omzet"}>
                <b>
                  {" "}
                  {rows.reduce(
                    (prev, cur) => Number(cur.data[0][column.key]) + prev,
                    0
                  )}
                </b>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <b>Kosten</b>
            </TableCell>
            {columns.map((column) => (
              <TableCell key={column.key + "-kosten"}>
                <b>
                  {" "}
                  {rows.reduce(
                    (prev, cur) => Number(cur.data[1][column.key]) + prev,
                    0
                  )}
                </b>
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default WerkstroomFinanceTableComponent;
