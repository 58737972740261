import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import { verantwoordelijken } from "./verantwoordelijken";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2, 2, 1, 2),
    minWidth: 170,
  },
}));

const TreeFilter = () => {
  const classes = useStyles();

  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">Verantwoordelijke</InputLabel>
      <Select id="demo-simple-select" value={value} onChange={handleChange}>
        <MenuItem value="">
          <em>Geen</em>
        </MenuItem>
        {verantwoordelijken.map((verantwoordelijke, index) => (
          <MenuItem key={index} value={verantwoordelijke}>
            {verantwoordelijke}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    // <FormControl variant="outlined" className={classes.formControl}>
    //   <InputLabel>Verantwoordelijke</InputLabel>
    //   <Select
    //     value={verantwoordelijk}
    //     onChange={(e) => setVerantwoordelijke(e)}
    //   >
    //     <MenuItem value="">
    //       <em>Geen</em>
    //     </MenuItem>

    //   </Select>
    // </FormControl>
  );
};

export default TreeFilter;
