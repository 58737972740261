import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Tooltip from "@material-ui/core/Tooltip";

const AddPeriod = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [date, changeDate] = useState(new Date());

  const onChangeDate = (date) => {
    props.addPeriod(date);
    changeDate(date);
  };

  return (
    <>
      <ButtonGroup color="primary">
        <Tooltip title="Selecteer het startjaar van het financiële boekjaar">
          <Button onClick={() => setIsOpen(true)}> Voeg periode toe </Button>
        </Tooltip>
        <Button onClick={props.addInstrument}>Voeg instrument toe</Button>
      </ButtonGroup>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          views={["year"]}
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          label="Selecteer fiscaal startjaar"
          value={date}
          onChange={(date) => onChangeDate(date)}
          TextFieldComponent={() => null}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

export default AddPeriod;
