import React from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { ErrorBar } from "../snackbar";

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    outline: "none",
    maxHeight: "90vh",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      width: "95vw",
      height: "95vh"
    }
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    "& svg": {
      height: "100%",
      color: "gray"
    }
  }
  // content: {
  //   flexGrow: 1,

  // }
}));

const SkadiModal = ({
  open,
  children,
  onClose,
  onEnter,
  title,
  error,
  setError
}) => {
  const classes = useStyles();

  if (!open) return <></>;
  else
    return (
      <>
        <Modal
          open={open}
          onClose={onClose}
          onKeyPress={e => {
            if (onEnter && e.key === "Enter") onEnter();
          }}
        >
          <div className={classes.root}>
            <div className={classes.header}>
              <h2>{title}</h2>
              <div>
                <CloseIcon onClick={onClose} />
              </div>
            </div>
            <div className={classes.content}>{children}</div>
          </div>
        </Modal>
        <ErrorBar error={error} setError={setError} />
      </>
    );
};

export default SkadiModal;
