import React, { useState, forwardRef, useContext, useEffect } from "react";
import Moment from "moment";
import { StateContext } from "../index";
import AddKlussenEerstejaars from "./addKlussenEerstejaars";
import EditKlussenEerstejaars from "./editKlussenEerstejaars";
import PdfKlussenEerstejaars from "./../pdfKlussenEerstejaars";
import { addLog, incrementSjaars } from "../helpers";

import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import AddIcon from "@material-ui/icons/Add";

import firebase from "firebase";

export const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    marginTop: theme.spacing(1),
    justifyContent: "space-between",
  },

  addrenter: {
    color: "white",
    margin: theme.spacing(1),
  },
}));

const deleteKlussenEerstejaars = (rowData, context) => {
  firebase
    .firestore()
    .collection("klussen-eerstejaars" + context.state.year)
    .doc(rowData.id)
    .delete();
  firebase
    .firestore()
    .collection("klussen-indeling" + context.state.year)
    .where("klus", "==", rowData.id)
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        incrementSjaars(
          doc.data().eerstejaars,
          "klussen",
          Number(-rowData.weging),
          context.state.year
        );
        addLog(
          doc.data().eerstejaars,
          "delete",
          "Verwijdert van klus: " + rowData.naam,
          false,
          rowData.id,
          rowData.naam,
          context.state.year
        );
        doc.ref.delete();
      });
    });
};

const KlussenEerstejaars = ({ sjaars, indeling, klussen }) => {
  const classes = useStyles();
  const context = useContext(StateContext);

  const [edit, setEdit] = useState(null);
  const [add, setAdd] = useState(null);
  const [value, setValue] = useState([]);
  // const [value, loading, error] = useCollectionData(
  //   firebase.firestore().collection("klussen-eerstejaars"),
  //   { idField: "id" }
  // );

  useEffect(() => {
    const tableValues = [];
    klussen.forEach((doc) => {
      const found = indeling.filter((s) => s.klus === doc.id);
      const voltooid = found.filter((item) => item.voltooid === true).length;
      const boete = found.filter((item) => item.boete === true).length;
      const obj = { voltooid: voltooid, boete: boete };
      tableValues.push({ ...doc, ...obj });
    });
    setValue(tableValues);
    return () => {};
  }, [klussen, indeling]);

  // if (error) return error;
  return (
    <>
      <MaterialTable
        title="Eerstejaars Klussen"
        isLoading={value === []}
        onRowClick={(event, rowData) =>
          context.actions.setKlus({ id: rowData.id, naam: rowData.naam })
        }
        columns={[
          { title: "Klus", field: "naam" },
          {
            title: "Datum",
            field: "datum",
            type: "datetime",
            defaultSort: "desc",
            render: (row) => Moment(row.datum.seconds * 1000).format("L"),
          },
          {
            title: "Van",
            field: "van",
            type: "datetime",
            render: (row) => Moment(row.van.seconds * 1000).format("LT"),
          },
          {
            title: "Tot",
            field: "tot",
            type: "datetime",
            render: (row) => Moment(row.tot.seconds * 1000).format("LT"),
          },
          { title: "Aantal", field: "aantal" },
          { title: "Voltooid", field: "voltooid" },
          { title: "Boete", field: "boete" },
        ]}
        data={value}
        actions={[
          {
            icon: () => <Edit />,
            tooltip: "Bewerk Klus",
            onClick: (event, rowData) => setEdit(rowData),
          },
          {
            icon: () => <DeleteOutline />,
            tooltip: "Verwijder Klus",
            onClick: (event, rowData) => {
              if (
                window.confirm(
                  "Weet je zeker dat je deze klus wilt verwijderen? (dit kan niet ongedaan gemaakt worden)"
                )
              )
                deleteKlussenEerstejaars(rowData, context);
              context.actions.setKlus(null);
            },
          },
        ]}
        options={{
          headerStyle: { padding: "14px 16px 14px 16px" },
          pageSize: 50,
          pageSizeOptions: [20, 50, 200],
          emptyRowsWhenPaging: false,
          actionsColumnIndex: -1,
          rowStyle: (rowData) =>
            context.state.selectedKlus &&
            context.state.selectedKlus.id === rowData.id
              ? { backgroundColor: "#EEE" }
              : {},
        }}
        icons={tableIcons}
        localization={{
          toolbar: { searchPlaceholder: "Zoeken" },
        }}
      />
      <div className={classes.buttons}>
        <PdfKlussenEerstejaars
          collection="klussen"
          title="EERSTEJAARSKLUSSEN"
          months={context.state.months}
        />
        <Fab
          color="primary"
          aria-label="add"
          className={classes.addrenter}
          onClick={() => setAdd(true)}
        >
          <AddIcon />
        </Fab>
      </div>
      <AddKlussenEerstejaars id={add} setId={setAdd} sjaars={sjaars} />
      <EditKlussenEerstejaars id={edit} setId={setEdit} />
    </>
  );
};

export default KlussenEerstejaars;
