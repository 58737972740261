import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import firebase from "firebase";
import Moment from "moment";
import { StateContext } from "../index";

import { components } from "./SelectSjaars";
import SkadiModal from "../../../../components/modal/skadiModal";
import SkadiSnackbar from "../../../../components/snackbar";
import { addBatchLog } from "../helpers";

import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, emphasize } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: 400,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: 350,
  },
  formControl: {
    margin: theme.spacing(2, 0, 1, 0),
    minWidth: 120,
    height: 48,
  },
  checkers: {
    margin: theme.spacing(1, 0, 2, 0),
    minWidth: 120,
    height: 48,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttons: {
    marginTop: "auto",
    paddingTop: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
  },
  applybutton: {
    color: "white",
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
}));

const ChangeForm = ({
  klus,
  handleClose,
  sjaars,
  klussenEerstejaars,
  type,
  selected,
  setSelected,
}) => {
  const classes = useStyles();

  const [error, setError] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [suggestions, setSuggestions] = React.useState([]);
  const [klussen, setKlussen] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [change, setChange] = useState(null);
  const [r] = useState(klus);

  const context = useContext(StateContext);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(type + "-indeling" + context.state.year)
      .get()
      .then(
        (snapshot) => {
          const klussen = [];
          const suggestions = [];
          snapshot.forEach((doc) => {
            const klus = doc.data();
            if (klus.eerstejaars) {
              const found = sjaars.find((s) => {
                return s.id === klus.eerstejaars;
              });
              const foundKlus = klussenEerstejaars.find((s) => {
                return s.id === klus.klus;
              });
              klussen.push({ id: doc.id, sjaars: found, data: doc.data() });
              suggestions.push({
                value: doc.id,
                label:
                  found.voornaam +
                  " " +
                  (found.tussenvoegsel
                    ? found.tussenvoegsel + " " + found.achternaam
                    : found.achternaam) +
                  ": " +
                  Moment(foundKlus.datum.seconds * 1000).format("L") +
                  " " +
                  foundKlus.naam,
              });
            }
          });
          setLoading(false);
          setKlussen(klussen);
          setSuggestions(
            suggestions.sort((a, b) => {
              return a.label.localeCompare(b.label);
            })
          );
        },
        (err) => {
          setError(err);
        }
      );

    return () => unsubscribe;
  }, [selected, sjaars, klussenEerstejaars, type, context.state.year]);

  const handleSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const saveSjaars = () => {
    if (!change) {
      setOpenError(true);
    } else {
      const db = firebase.firestore();
      let batch = db.batch();
      batch.update(
        db.collection(type + "-indeling" + context.state.year).doc(r.id),
        {
          eerstejaars: change.data.eerstejaars,
          klus: r.data.klus,
          voltooid: r.data.voltooid,
        }
      );
      batch.update(
        db.collection(type + "-indeling" + context.state.year).doc(change.id),
        {
          eerstejaars: r.data.eerstejaars,
          klus: change.data.klus,
          voltooid: change.data.voltooid,
        }
      );
      const foundChange = suggestions.find((s) => {
        return s.value === r.id;
      });
      const foundKlus = klussenEerstejaars.find((s) => {
        return s.id === change.data.klus;
      });
      addBatchLog(
        batch,
        change.data.eerstejaars,
        "change",
        "Geruild met " + foundChange.label,
        type !== "klussen",
        r.data.klus,
        foundChange.label,
        context.state.year
      );
      addBatchLog(
        batch,
        r.data.eerstejaars,
        "change",
        "Geruild met " +
          change.sjaars.voornaam +
          " " +
          change.sjaars.achternaam +
          ": " +
          foundKlus.naam,
        type !== "klussen",
        change.data.klus,
        foundKlus.naam,
        context.state.year
      );
      batch.commit().then(() => {
        handleClose();
        setSelected({
          id: r.data.klus,
          naam: selected.naam,
        });
      });
    }
  };

  if (loading) return <></>;
  return (
    <>
      <SkadiModal
        open={true}
        onClose={handleClose}
        title={"Klus wisselen"}
        onEnter={saveSjaars}
        error={error}
        setError={setError}
      >
        <div className={classes.root}>
          <form className={classes.form}>
            <TextField
              disabled
              id="standard-error"
              label="Oorspronkelijk"
              defaultValue={
                r.sjaars.voornaam +
                " " +
                (r.sjaars.tussenvoegsel
                  ? r.sjaars.tussenvoegsel + " " + r.sjaars.achternaam
                  : r.sjaars.achternaam)
              }
              className={classes.textField}
              margin="normal"
            />
            <Select
              classes={classes}
              inputId="react-select-single"
              TextFieldProps={{
                label: "Ruilen met",
                InputLabelProps: {
                  htmlFor: "react-select-single",
                  shrink: true,
                },
              }}
              placeholder="Selecteer een Sjaars"
              options={suggestions}
              components={components}
              // defaultValue={defaultSjaars}
              onChange={(e) => {
                const found = klussen.find((s) => {
                  return s.id === e.value;
                });
                setChange(found);
              }}
            />
          </form>
          <div className={classes.buttons}>
            <Button color="primary" onClick={handleClose}>
              Annuleren
            </Button>
            <Button
              className={classes.applybutton}
              color="primary"
              variant="contained"
              onClick={saveSjaars}
            >
              Opslaan
            </Button>
          </div>
        </div>
      </SkadiModal>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openError}
        autoHideDuration={6000}
        onClose={handleSnack}
      >
        <SkadiSnackbar
          onClose={handleSnack}
          variant="error"
          message={"Voer een ruiling in"}
        />
      </Snackbar>
    </>
  );
};

const ChangeKlussenIndeling = ({
  klus,
  setId,
  sjaars,
  klussenEerstejaars,
  type,
  selected,
  setSelected,
}) => {
  const open = klus != null;

  const handleClose = () => {
    if (open) setId(null);
  };
  if (!open) return <></>;
  else
    return (
      <ChangeForm
        klus={klus}
        handleClose={handleClose}
        sjaars={sjaars}
        klussenEerstejaars={klussenEerstejaars}
        type={type}
        selected={selected}
        setSelected={setSelected}
      />
    );
};

export default ChangeKlussenIndeling;
