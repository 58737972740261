import React, { useContext, useState, useEffect } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import MaterialTable from "material-table";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import { MeerjarenContext } from "./index";
import firebase from "firebase";
import { ErrorBarTimeout } from "./../../../components/snackbar";
import { tableIcons } from "./../klussen/klussen-eerstejaars/klussenEerstejaars";
import { deleteAtPath } from "./../../../components/functionHelpers";
import FinanceTableComponent from "./financeTable";
import { verantwoordelijken } from "./verantwoordelijken";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    minWidth: "100%",
  },
}));

const TableComponent = ({ value }) => {
  const classes = useStyles();

  const context = useContext(MeerjarenContext);

  const [tableValue, setTableValue] = useState([]);

  useEffect(() => {
    const tableValues = [];
    value.forEach((doc) => {
      tableValues.push({
        naam: doc.naam,
        verantwoordelijke: doc.verantwoordelijke,
        id: doc.id,
        definitie: doc.definitie,
      });
    });
    console.log(tableValues);
    setTableValue(tableValues);
    return () => {};
  }, [value]);

  return (
    <MaterialTable
      style={{
        boxShadow: "0px 0px 0px rgba(0,0,0,0.0)",
        backgroundColor: "transparent",
      }}
      title="Projecten"
      isLoading={value === []}
      onRowClick={(event, rowData) =>
        context.actions.handleDrawerFromDrawer(
          context.state.selected + "/project/" + rowData.id,
          "project"
        )
      }
      columns={[
        { title: "Naam", field: "naam" },
        {
          title: "Verantwoordelijke",
          field: "verantwoordelijke",
          editComponent: (props) => (
            <FormControl className={classes.formControl}>
              <Select
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">
                  <em>Geen</em>
                </MenuItem>
                {verantwoordelijken.map((verantwoordelijke) => (
                  <MenuItem value={verantwoordelijke}>
                    {verantwoordelijke}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ),
        },
        {
          title: "Definitie",
          field: "definitie",
          editComponent: (props) => (
            <TextField
              className={classes.textField}
              multiline
              maxRows={4}
              value={props.value}
              onChange={(e) => props.onChange(e.target.value)}
            />
          ),
        },
      ]}
      data={tableValue}
      options={{
        headerStyle: {
          backgroundColor: "transparent",
          padding: "14px 16px 14px 16px",
        },
        pageSize: 5,
        pageSizeOptions: [5, 10],
        emptyRowsWhenPaging: false,
      }}
      icons={tableIcons}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve, reject) => {
            newData.parent = firebase.firestore().doc(context.state.selected);
            firebase
              .firestore()
              .doc(context.state.selected)
              .collection("project")
              .add(newData)
              .then(() => {
                context.actions.handleTreeReload();
                resolve();
              })
              .catch(function(error) {
                console.error("Error adding document: ", error);
                resolve();
              });
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            delete newData.id;
            firebase
              .firestore()
              .doc(context.state.selected)
              .collection("project")
              .doc(oldData.id)
              .update(newData)
              .then(() => resolve())
              .catch(function(error) {
                console.error("Error removing document: ", error);
                resolve();
              });
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            deleteAtPath(context.state.selected + "/project/" + oldData.id)
              .then(() => {
                context.actions.handleTreeReload();
                resolve();
              })
              .catch(function(error) {
                console.error("Error removing document: ", error);
                resolve();
              });
          }),
      }}
      localization={{
        toolbar: { searchPlaceholder: "Zoeken" },
        body: {
          addTooltip: "Toevoegen",
        },
      }}
    />
  );
};

const ProgrammaComponent = () => {
  const context = useContext(MeerjarenContext);

  const [value, loading, error] = useCollectionData(
    firebase
      .firestore()
      .doc(context.state.selected)
      .collection("project"),
    {
      idField: "id",
    }
  );

  if (error) {
    return <ErrorBarTimeout error={error} />;
  }

  return (
    <>
      {!loading ? <TableComponent value={value} /> : null}
      <FinanceTableComponent programma={context.state.selected} />
    </>
  );
};

export default ProgrammaComponent;
