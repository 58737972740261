import React, { useState, useContext } from "react";
import SkadiModal from "../../../../components/modal/skadiModal";
import SkadiSnackbar from "../../../../components/snackbar";
import firebase from "firebase";
import moment from "moment";
import { StateContext } from "../index";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: 350,
    "& div": {
      width: "100%",
    },
  },
  formControl: {
    margin: theme.spacing(2, 0, 1, 0),
    minWidth: 120,
    height: 48,
  },
  checkers: {
    margin: theme.spacing(1, 0, 2, 0),
    minWidth: 120,
    height: 48,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttons: {
    marginTop: "auto",
    paddingTop: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
  },
  applybutton: {
    color: "white",
  },
}));

const AddForm = ({ klus, handleClose }) => {
  const classes = useStyles();
  const context = useContext(StateContext);

  klus.datum = moment(klus.datum.seconds * 1000);
  klus.tot = moment(klus.tot.seconds * 1000);
  klus.van = moment(klus.van.seconds * 1000);

  const [error, setError] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [r, setKlus] = useState(klus);
  const handleChange = (e, n) => setKlus({ ...r, [n]: e.target.value });

  const handleSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const saveSjaars = () => {
    if (!r.datum || !r.van || !r.tot || !r.naam || !r.aantal) {
      setOpenError(true);
    } else {
      const date = r.datum.startOf("day");
      const van = moment(
        date.format("LL") + " " + r.van.format("LT"),
        "LLL"
      ).toDate();
      const tot = moment(
        date.format("LL") + " " + r.tot.format("LT"),
        "LLL"
      ).toDate();

      const db = firebase.firestore();
      const ref = db
        .collection("klussen-eerstejaars" + context.state.year)
        .doc(r.id);
      let batch = db.batch();
      batch.update(ref, {
        naam: r.naam,
        datum: date.toDate(),
        van: van,
        tot: tot,
      });
      batch.commit().then(() => {
        handleClose();
        context.actions.setKlus({ id: r.id, naam: r.naam });
      });
    }
  };
  return (
    <>
      <SkadiModal
        open={true}
        onClose={handleClose}
        title={"Klus toevoegen"}
        onEnter={saveSjaars}
        error={error}
        setError={setError}
      >
        <div className={classes.root}>
          <form className={classes.form}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} lg={12}>
                <TextField
                  required
                  key={"naam"}
                  label={"Klus"}
                  value={r["naam"] ? r["naam"] : ""}
                  onChange={(e) => handleChange(e, "naam")}
                  margin="normal"
                />
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    required
                    clearable
                    margin="normal"
                    id="date-picker"
                    label="Datum"
                    format="DD/MM/YYYY"
                    placeholder="28/10/1926"
                    value={r["datum"]}
                    onChange={(e) => setKlus({ ...r, datum: e })}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <KeyboardTimePicker
                    required
                    ampm={false}
                    label="Van"
                    value={r.van}
                    onChange={(e) => setKlus({ ...r, van: e })}
                  />
                  <KeyboardTimePicker
                    required
                    ampm={false}
                    label="Tot"
                    value={r.tot}
                    onChange={(e) => setKlus({ ...r, tot: e })}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </form>
          <div className={classes.buttons}>
            <Button color="primary" onClick={handleClose}>
              Annuleren
            </Button>
            <Button
              className={classes.applybutton}
              color="primary"
              variant="contained"
              onClick={saveSjaars}
            >
              Opslaan
            </Button>
          </div>{" "}
        </div>
      </SkadiModal>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openError}
        autoHideDuration={6000}
        onClose={handleSnack}
      >
        <SkadiSnackbar
          onClose={handleSnack}
          variant="error"
          message={"Vul alle velden in"}
        />
      </Snackbar>
    </>
  );
};

const EditKlussenEerstejaars = ({ id, setId }) => {
  const open = id != null;
  const handleClose = () => {
    if (open) setId(null);
  };
  if (!open) return <></>;
  else return <AddForm klus={id} handleClose={handleClose} />;
};

export default EditKlussenEerstejaars;
