import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";

import firebase from "firebase";
import { SkadiInput } from "../klussen/sjaars/editsjaars";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
}));

const BoeteForm = ({ data }) => {
  const classes = useStyles();

  const [r, setBoete] = useState(data);
  const handleChange = (e, n) => setBoete({ ...r, [n]: e.target.value });

  const handleSubmit = () => {
    firebase
      .firestore()
      .collection("boetes")
      .doc("data")
      .update({
        secretaris: r.secretaris,
        consumabel: r.consumabel,
        bestuursjaar: r.bestuursjaar,
        boete_numeriek: r.boete_numeriek,
        boete_zegge: r.boete_zegge,
      });
  };

  return (
    <>
      <Grid item xs={12} sm={12}>
        <form className={classes.container} noValidate autoComplete="off">
          <SkadiInput
            r={r}
            handleChange={handleChange}
            fields={[
              { label: "Secretaris", field: "secretaris" },
              { label: "Consumabel", field: "consumabel" },
              { label: "Bestuursjaar", field: "bestuursjaar" },
              { label: "Bedrage Boete (numeriek)", field: "boete_numeriek" },
              { label: "Bedrag Boete (zegge)", field: "boete_zegge" },
            ]}
          />
        </form>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={handleSubmit}
        >
          Opslaan
        </Button>
      </Grid>
    </>
  );
};

const AccountComponent = () => {
  const [boeteData, boeteLoading] = useDocumentData(
    firebase
      .firestore()
      .collection("boetes")
      .doc("data")
  );

  console.log(boeteData);

  return (
    <Grid container spacing={0}>
      {boeteLoading && <span>Loading...</span>}
      {boeteData && <BoeteForm data={boeteData} />}
    </Grid>
  );
};

export default AccountComponent;
