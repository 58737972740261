export const verantwoordelijken = [
  "President",
  "Secretaris",
  "Penningmeester",
  "CvR",
  "CvM",
  "CvF&C",
  "CEB",
  "CvC",
  "CvG",
  "DB",
];
