import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import RichTextEditor from "react-rte";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: "100%",
    },
  },
  button: {
    margin: theme.spacing(2),
  },
}));

const CommentForm = (props) => {
  const classes = useStyles();

  const [text, setText] = useState(RichTextEditor.createEmptyValue());

  const onRichChange = (e) => {
    setText(e);
  };

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    props.onSubmit(text.toString("html"));
    setText(RichTextEditor.createEmptyValue());
  };

  return (
    <form onSubmit={onSubmit} className={classes.root}>
      <RichTextEditor
        rootStyle={{
          border: "none",
          fontFamily: "roboto",
          width: "100%",
          margin: "16px",
        }}
        toolbarConfig={{
          display: [
            "INLINE_STYLE_BUTTONS",
            "BLOCK_TYPE_BUTTONS",
            "LINK_BUTTONS",
            "BLOCK_TYPE_DROPDOWN",
            "HISTORY_BUTTONS",
          ],
          INLINE_STYLE_BUTTONS: [
            { label: "Bold", style: "BOLD", className: "custom-css-class" },
            { label: "Italic", style: "ITALIC" },
            { label: "Underline", style: "UNDERLINE" },
          ],
          BLOCK_TYPE_DROPDOWN: [
            { label: "Normal", style: "unstyled" },
            { label: "Heading Large", style: "header-one" },
            { label: "Heading Medium", style: "header-two" },
            { label: "Heading Small", style: "header-three" },
          ],
          BLOCK_TYPE_BUTTONS: [
            { label: "UL", style: "unordered-list-item" },
            { label: "OL", style: "ordered-list-item" },
          ],
        }}
        placeholder={"Typ een comment"}
        value={text}
        onChange={onRichChange}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.button}
      >
        Comment
      </Button>
    </form>
  );
};

export default CommentForm;
