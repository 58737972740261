import React, { useState } from "react";
import { produce } from "immer";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import PdfBoete from "./pdfBoete";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  noPadding: {
    padding: 0
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%"
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    width: "100%"
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  }
}));

const BoetesComponent = () => {
  const classes = useStyles();
  const [values, setValues] = useState({
    voornaam: "",
    naam: "",
    geslacht: "Man",
    bedrag: 50,
    zegge: "vijftig",
    reden: "",
    artikel: "",
    artikelInhoud: ""
  });

  const [pdfValues, setPdfValues] = useState({
    geslacht: "Man",
    voornaam: "Olaf",
    naam: "Wallaart",
    bedrag: "25",
    zegge: "vijfentwintig",
    reden:
      "u op 15 oktober j.l. niet aanwezig was bij uw sjaarsklus. Voor het niet aanwezig zijn bij een sjaarsklus volgt een geldelijke boete van 50 euro, zegge: vijftig euro.",
    artikel: "Artikel 4 lid 6",
    artikelInhoud:
      "Indien een vaarverbod van kracht is, is het verboden het water op te gaan. Het negeren van een door het bestuur afgekondigd vaarverbod wordt bestraft met een boete van €25,- (zegge vijfentwintig) voor iedere betrokken roeier en/of stuurman/vrouw alsmede waar toepasselijk een schadevergoedingsmaatregel."
  });

  const handleSubmit = values => {
    const newState = produce(pdfValues, () => values);
    setPdfValues(newState);
    console.log(newState);
  };

  const handleChange = naam => event => {
    setValues({ ...values, [naam]: event.target.value });
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={6} lg={6}>
        <form className={classes.container} noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                required
                id="standard-voorname"
                label="Voornaam"
                value={values.voornaam}
                className={classes.textField}
                onChange={handleChange("voornaam")}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                required
                id="standard-name"
                label="Achternaam"
                value={values.naam}
                className={classes.textField}
                onChange={handleChange("naam")}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl
                variant="outlined"
                component="fieldset"
                className={classes.formControl}
              >
                <FormLabel component="legend">Geslacht</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={values.geslacht}
                  onChange={handleChange("geslacht")}
                  row
                >
                  <FormControlLabel
                    value="Vrouw"
                    control={<Radio />}
                    label="Vrouw"
                  />
                  <FormControlLabel
                    value="Man"
                    control={<Radio />}
                    label="Man"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                required
                id="standard-number"
                label="Bedrag"
                type="number"
                className={classes.textField}
                value={values.bedrag}
                onChange={handleChange("bedrag")}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                id="standard-multiline-static"
                label="Zegge"
                className={classes.textField}
                value={values.zegge}
                onChange={handleChange("zegge")}
              />
            </Grid>
            <Grid item xs={12} sm={10}>
              <TextField
                variant="outlined"
                id="standard-multiline-static"
                label="Reden"
                className={classes.textField}
                value={values.reden}
                onChange={handleChange("reden")}
                multiline
                rows="5"
              />
            </Grid>
            <Grid item xs={12} sm={10}>
              <TextField
                required
                variant="outlined"
                id="standard-name"
                label="Artikel"
                className={classes.textField}
                value={values.artikel}
                onChange={handleChange("artikel")}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={10}>
              <TextField
                variant="outlined"
                id="standard-multiline-static"
                label="Artikel Inhoud"
                className={classes.textField}
                value={values.artikelInhoud}
                onChange={handleChange("artikelInhoud")}
                multiline
                rows="5"
              />
            </Grid>
          </Grid>
        </form>
        <Grid item xs={12} sm={10}>
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            onClick={() => handleSubmit(values)}
          >
            Opslaan & PDF Genereren
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <PdfBoete values={pdfValues} />
      </Grid>
    </Grid>
  );
};

export default BoetesComponent;
