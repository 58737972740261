import React, { useState, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";

import SkadiModal from "components/modal/skadiModal";
import firebase from "firebase";
import { SkadiInput } from "../klussen/sjaars/editsjaars";
import { MeerjarenContext } from "./index";
import { verantwoordelijken } from "./verantwoordelijken";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      minWidth: 1000,
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    "& div": {
      width: "100%",
    },
  },
  formControl: {
    margin: theme.spacing(2, 0, 1, 0),
    minWidth: 120,
    height: 48,
  },
  textField: {
    minWidth: "100%",
    margin: theme.spacing(2, 0, 1, 0),
  },
}));

const DrawerModal = ({ value, handleClose }) => {
  const context = useContext(MeerjarenContext);
  const classes = useStyles();

  const [data, setData] = useState(value);

  const handleChange = (e, n) => setData({ ...data, [n]: e.target.value });
  const [error, setError] = useState(false);

  const saveData = () => {
    const values =
      context.state.type === "programma"
        ? {
            naam: data.naam,
            hoofddoel: data.hoofddoel,
            verantwoordelijke: data.verantwoordelijke,
            definitie: data.definitie,
          }
        : context.state.type === "werkstroom"
        ? {
            naam: data.naam,
            hoofddoel: data.hoofddoel,
            verantwoordelijke: data.verantwoordelijke,
          }
        : {
            naam: data.naam,
            definitie: data.definitie,
            procesdoelen: data.procesdoelen,
            verantwoordelijke: data.verantwoordelijke,
          };
    firebase
      .firestore()
      .doc(context.state.selected)
      .update(values)
      .then(() => {
        context.actions.handleTreeReload();
        handleClose();
      });
  };

  return (
    <SkadiModal
      open={true}
      onClose={handleClose}
      title={"Programma aanpassen"}
      onEnter={saveData}
      error={error}
      setError={setError}
    >
      <div className={classes.root}>
        <form className={classes.form}>
          <SkadiInput
            r={data}
            handleChange={handleChange}
            fields={[{ label: "Naam", field: "naam" }]}
          />
          <FormControl className={classes.formControl}>
            <InputLabel>verantwoordelijke</InputLabel>
            <Select
              value={data.verantwoordelijke}
              onChange={(e) => handleChange(e, "verantwoordelijke")}
              displayEmpty
            >
              <MenuItem value="">
                <em>Geen</em>
              </MenuItem>
              {verantwoordelijken.map((verantwoordelijke) => (
                <MenuItem value={verantwoordelijke}>
                  {verantwoordelijke}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {context.state.type === "programma" ? (
            <>
              <TextField
                className={classes.textField}
                label="Definitie Programma"
                multiline
                rows={4}
                value={data.definitie}
                onChange={(e) => handleChange(e, "hoofddoel")}
              />
              <TextField
                className={classes.textField}
                label="Hoofddoelen Programma"
                multiline
                rows={4}
                value={data.hoofddoel}
                onChange={(e) => handleChange(e, "hoofddoel")}
              />
            </>
          ) : context.state.type === "werkstroom" ? (
            <TextField
              className={classes.textField}
              label="Doel Werkstroom"
              multiline
              rows={4}
              value={data.hoofddoel}
              onChange={(e) => handleChange(e, "hoofddoel")}
            />
          ) : (
            <>
              <TextField
                className={classes.textField}
                label="Definitie"
                multiline
                rows={4}
                value={data.definitie}
                onChange={(e) => handleChange(e, "definitie")}
              />
              <TextField
                className={classes.textField}
                label="Procesdoelen"
                multiline
                rows={4}
                value={data.procesdoelen}
                onChange={(e) => handleChange(e, "achtergrond")}
              />
            </>
          )}
        </form>
        <div className={classes.buttons}>
          <Button color="primary" onClick={handleClose}>
            Annuleren
          </Button>
          <Button
            className={classes.applybutton}
            color="primary"
            variant="contained"
            onClick={saveData}
          >
            Opslaan
          </Button>
        </div>
      </div>
    </SkadiModal>
  );
};

const EditDrawer = ({ value, open, setEdit }) => {
  const handleClose = () => {
    if (open) setEdit(false);
  };
  if (!open) return <></>;
  else return <DrawerModal value={value} handleClose={handleClose} />;
};

export default EditDrawer;
