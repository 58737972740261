import React, { useState, useContext } from "react";
import SkadiModal from "../../../../components/modal/skadiModal";
import SkadiSnackbar from "../../../../components/snackbar";
import { components } from "./SelectSjaars";
import firebase from "firebase";
import produce from "immer";
import Select from "react-select";

import { StateContext } from "../index";
import { addBatchLog } from "../helpers";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";

import { makeStyles, emphasize } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: 420,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: 350,
  },
  formControl: {
    margin: theme.spacing(2, 0, 1, 0),
    minWidth: 120,
    height: 48,
  },
  checkers: {
    margin: theme.spacing(1, 0, 2, 0),
    minWidth: 120,
    height: 48,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttons: {
    marginTop: "auto",
    paddingTop: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
  },
  applybutton: {
    color: "white",
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  textField: {
    width: "100%",
  },
  label: {
    marginTop: theme.spacing(4),
  },
}));

const AddForm = ({
  klus,
  handleClose,
  sjaars,
  type,
  selected,
  setSelected,
}) => {
  const classes = useStyles();

  const context = useContext(StateContext);

  const [error, setError] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [r, setKlus] = useState(klus);

  const handleChange = (e, n) => {
    const newState = produce(r, (draftstate) => {
      draftstate.data[n] = e.target.checked;
    });
    setKlus(newState);
  };

  const handleText = (e, n) => {
    const newState = produce(r, (draftstate) => {
      draftstate.data[n] = e.target.value;
    });
    setKlus(newState);
  };

  const handleSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const saveSjaars = () => {
    if (!r.data.eerstejaars) {
      setOpenError(true);
    } else if (!r.id) {
      const db = firebase.firestore();
      const ref = db.collection(type + "-indeling" + context.state.year).doc();
      let batch = db.batch();
      batch.set(ref, {
        eerstejaars: r.data.eerstejaars,
        klus: r.data.klus,
        voltooid: r.data.voltooid,
        boete: r.data.boete,
        opmerking: r.data.opmerking,
      });
      batch.update(
        db
          .collection("eerstejaars" + context.state.year)
          .doc(r.data.eerstejaars),
        type === "klussen"
          ? {
              klussen: firebase.firestore.FieldValue.increment(
                Number(selected.weging)
              ),
            }
          : { kookklussen: firebase.firestore.FieldValue.increment(1) }
      );
      batch.update(
        db
          .collection(type + "-eerstejaars" + context.state.year)
          .doc(r.data.klus),
        {
          aantal: firebase.firestore.FieldValue.increment(1),
        }
      );
      addBatchLog(
        batch,
        r.data.eerstejaars,
        "add",
        "Toegevoegd aan klus: " + selected.naam,
        type !== "klussen",
        selected.id,
        selected.naam,
        context.state.year
      );
      batch.commit().then(() => {
        handleClose();
        setSelected({
          id: r.data.klus,
          naam: selected.naam,
        });
      });
    } else {
      const db = firebase.firestore();
      const ref = db
        .collection(type + "-indeling" + context.state.year)
        .doc(r.id);
      let batch = db.batch();
      batch.update(ref, {
        eerstejaars: r.data.eerstejaars,
        voltooid: r.data.voltooid,
        boete: r.data.boete,
        opmerking: r.data.opmerking,
      });
      addBatchLog(
        batch,
        r.data.eerstejaars,
        "add",
        "Toegevoegd aan klus: " + selected.naam,
        type !== "klussen",
        selected.id,
        selected.naam,
        context.state.year
      );
      batch.update(
        db
          .collection("eerstejaars" + context.state.year)
          .doc(r.data.eerstejaars),
        type === "klussen"
          ? {
              klussen: firebase.firestore.FieldValue.increment(
                Number(selected.weging)
              ),
            }
          : {
              kookklussen: firebase.firestore.FieldValue.increment(1),
            }
      );
      if (klus.sjaars) {
        addBatchLog(
          batch,
          klus.data.eerstejaars,
          "delete",
          "Verwijdert van klus: " + selected.naam,
          type !== "klussen",
          selected.id,
          selected.naam,
          context.state.year
        );
        batch.update(
          db
            .collection("eerstejaars" + context.state.year)
            .doc(klus.data.eerstejaars),
          type === "klussen"
            ? {
                klussen: firebase.firestore.FieldValue.increment(
                  Number(selected.weging) * -1
                ),
              }
            : {
                kookklussen: firebase.firestore.FieldValue.increment(-1),
              }
        );
      }
      if (r.data.voltooid) {
        addBatchLog(
          batch,
          klus.data.eerstejaars,
          "completed",
          "klus afgerond: " + selected.naam,
          type !== "klussen",
          selected.id,
          selected.naam,
          context.state.year
        );
      }
      batch.commit().then(() => {
        handleClose();
        setSelected({
          id: r.data.klus,
          naam: selected.naam,
        });
      });
    }
  };

  const suggestions = sjaars.map((suggestion) => ({
    value: suggestion.id,
    label:
      suggestion.voornaam +
      " " +
      (suggestion.tussenvoegsel
        ? suggestion.tussenvoegsel + " " + suggestion.achternaam
        : suggestion.achternaam),
  }));

  const defaultSjaars = {
    value: r.sjaars ? r.sjaars.id : null,
    label: r.sjaars
      ? r.sjaars.voornaam +
        " " +
        (r.sjaars.tussenvoegsel
          ? r.sjaars.achternaam
          : r.sjaars.tussenvoegsel + " " + r.sjaars.achternaam)
      : null,
  };
  return (
    <>
      <SkadiModal
        open={true}
        onClose={handleClose}
        title={"Klus bewerken"}
        onEnter={saveSjaars}
        error={error}
        setError={setError}
      >
        <div className={classes.root}>
          <form className={classes.form}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} lg={12}>
                <Select
                  classes={classes}
                  inputId="react-select-single"
                  TextFieldProps={{
                    label: "Eerstejaars",
                    InputLabelProps: {
                      htmlFor: "react-select-single",
                      shrink: true,
                    },
                  }}
                  placeholder="Selecteer een Sjaars"
                  options={suggestions}
                  components={components}
                  defaultValue={defaultSjaars}
                  onChange={(e) => {
                    const found = sjaars.find((s) => {
                      return s.id === e.value;
                    });
                    const newState = produce(r, (draftstate) => {
                      draftstate.data.eerstejaars = e.value;
                      draftstate.sjaars = found;
                    });
                    setKlus(newState);
                  }}
                />
                <FormLabel className={classes.label} component="legend">
                  Voltooid of Boete?
                </FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={r.data.voltooid}
                        onChange={(e) => handleChange(e, "voltooid")}
                        value="voltooid"
                        color="primary"
                      />
                    }
                    label="Voltooid"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={r.data.boete}
                        onChange={(e) => handleChange(e, "boete")}
                        value="boete"
                        color="primary"
                      />
                    }
                    label="Boete"
                  />
                </FormGroup>
                <TextField
                  id="standard-basic"
                  className={classes.textField}
                  label="Reden geen boete"
                  defaultValue={r.data.opmerking ? r.data.opmerking : ""}
                  onChange={(e) => handleText(e, "opmerking")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </form>
          <div className={classes.buttons}>
            <Button color="primary" onClick={handleClose}>
              Annuleren
            </Button>
            <Button
              className={classes.applybutton}
              color="primary"
              variant="contained"
              onClick={saveSjaars}
            >
              Opslaan
            </Button>
          </div>
        </div>
      </SkadiModal>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openError}
        autoHideDuration={6000}
        onClose={handleSnack}
      >
        <SkadiSnackbar
          onClose={handleSnack}
          variant="error"
          message={"Vul alle velden in"}
        />
      </Snackbar>
    </>
  );
};

const EditKlussenIndeling = ({
  klus,
  setId,
  sjaars,
  type,
  selected,
  setSelected,
}) => {
  const open = klus != null;

  const handleClose = () => {
    if (open) setId(null);
  };
  if (!open) return <></>;
  else
    return (
      <AddForm
        klus={klus}
        handleClose={handleClose}
        sjaars={sjaars}
        type={type}
        selected={selected}
        setSelected={setSelected}
      />
    );
};

export default EditKlussenIndeling;
