import app from "firebase/app";
import "firebase/firebase-firestore";
import "firebase/auth";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

app.initializeApp(config);
const Firebase = {
  firestore: app.firestore().settings({ experimentalForceLongPolling: true }),
  auth: app.auth,

  doSignInWithEmailAndPassword: (email, password) =>
    app
      .auth()
      .setPersistence(app.auth.Auth.Persistence.SESSION)
      .then(function() {
        return app.auth().signInWithEmailAndPassword(email, password);
      })
      .catch(function(error) {
        // Handle Errors here.
        console.log(error.message);
      }),

  doSignOut: () => app.auth().signOut(),
};
export default Firebase;
