import React, { useContext, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import { MeerjarenContext } from "./index";
import firebase from "firebase";
import WerkstroomComponent from "./werkstroom";
import ProgrammaComponent from "./programma";
import ProjectComponent from "./project";
import skadiColors from "./../../../skadiColors";
import CommentsComponent from "./comments/comments";
import EditDrawer from "./editDrawer";

const drawerWidth = "75%";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: skadiColors.grey5,
    border: "none",
    boxShadow: theme.shadows[4],
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  drawercontent: {
    padding: theme.spacing(1, 4),
  },
  info: {
    fontSize: 16,
    fontWeight: 300,
    "> b": {
      textTransform: "capitalize",
    },
  },
  textField: {
    minWidth: "100%",
  },
}));

const DrawerComponent = () => {
  const classes = useStyles();
  const context = useContext(MeerjarenContext);

  const [value, loading, error] = useDocumentData(
    firebase.firestore().doc(context.state.selected)
  );

  const [edit, setEdit] = useState(false);

  const handleChange = (event) => {
    console.log(event.target.value);
  };

  const handleEdit = () => {
    if (edit === true) {
      setEdit(false);
      // const newState = produce(rows, (draftstate) => {
      //   draftstate[row].naam = columnName;
      // });
      // setRows(newState);
      // setColumnName("");
      // submitToRemote(newState);
    } else {
      setEdit(true);
    }
  };

  const TypeComponent = () => {
    if (context.state.type === "werkstroom") {
      return <WerkstroomComponent />;
    } else if (context.state.type === "programma") {
      return <ProgrammaComponent />;
    } else if (context.state.type === "project") {
      return <ProjectComponent />;
    }
    return null;
  };

  const DrawerContent = () => {
    const Hoofddoel = () => {
      if (value.hoofddoel) {
        return (
          <Grid className={classes.info} item xs={12}>
            <b>Hoofddoel {context.state.type}: </b> {value.hoofddoel}
          </Grid>
        );
      }
      return null;
    };
    const Definitie = () => {
      if (value.definitie) {
        return (
          <Grid className={classes.info} item xs={12}>
            <b>Definitie: </b>
            {value.definitie}
          </Grid>
        );
      }
      return null;
    };
    const Procesdoelen = () => {
      if (value.achtergrond) {
        return (
          <Grid className={classes.info} item xs={12}>
            <b>Procesdoelen: </b>
            {value.procesdoelen}
          </Grid>
        );
      }
      return null;
    };

    return (
      <>
        <Grid container spacing={2}>
          <Grid className={classes.info} item xs={6}>
            {context.state.type}: <b>{value.naam}</b>
          </Grid>
          <Grid className={classes.info} item xs={6}>
            Verantwoordelijke: <b>{value.verantwoordelijke}</b>
          </Grid>
          <Hoofddoel />
          <Procesdoelen />
          <Definitie />
          <Grid item xs={12}>
            <TypeComponent />
            <CommentsComponent selected={context.state.selected} />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={context.state.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton
            color="primary"
            onClick={context.actions.handleDrawerClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h3">
            <IconButton onClick={handleEdit} className={classes.margin}>
              {loading ? (
                <CheckIcon fontSize="small" />
              ) : (
                <EditIcon fontSize="small" />
              )}
            </IconButton>
          </Typography>
        </div>
        <div className={classes.drawercontent}>
          {loading ? null : <DrawerContent />}
        </div>
      </Drawer>
      <EditDrawer value={value} open={edit} setEdit={setEdit} />
    </>
  );
};

export default DrawerComponent;
