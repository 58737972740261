import React, { useState, forwardRef, useContext } from "react";
import EditSjaars from "./editsjaars";
import UploadSjaars from "./uploadSjaars";

import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import firebase from "firebase";
import { StateContext } from "..";

export const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    margin: theme.spacing(1),
    justifyContent: "flex-end",
  },

  addrenter: {
    color: "white",
    margin: theme.spacing(1),
  },
}));

const deleteSjaars = (id, year) => {
  firebase
    .firestore()
    .collection("eerstejaars" + year)
    .doc(id)
    .delete();
};

const Sjaars = ({ value, loading, error }) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(null);

  const context = useContext(StateContext);

  if (error) return error;
  return (
    <>
      <MaterialTable
        isLoading={loading}
        title="Eerstejaars"
        onRowClick={(event, rowData) => setEdit(rowData.id)}
        columns={[
          { title: "Voornaam", field: "voornaam" },
          {
            title: "Achternaam",
            field: "achternaam",
            defaultSort: "asc",
            render: (row) =>
              row.tussenvoegsel == null
                ? row.achternaam
                : row.tussenvoegsel + " " + row.achternaam,
          },
          {
            title: "E-mail",
            field: "e-mail",
            render: (row) => (
              <a href={"mailto:" + row["e-mail"]}>{row["e-mail"]}</a>
            ),
          },
          {
            title: "Telefoon",
            field: "telefoon",
          },
          {
            title: "Aantal Klussen",
            field: "klussen",
          },
          {
            title: "Aantal Kookklussen",
            field: "kookklussen",
          },
        ]}
        data={value}
        actions={[
          {
            icon: () => <Edit />,
            tooltip: "Bewerk Sjaars",
            onClick: (event, rowData) => setEdit(rowData.id),
          },
          {
            icon: () => <DeleteOutline />,
            tooltip: "Verwijder Sjaars",
            onClick: (event, rowData) => {
              if (
                window.confirm(
                  "Weet je zeker dat je deze persoon wilt verwijderen? (dit kan niet ongedaan gemaakt worden)"
                )
              )
                deleteSjaars(rowData.id, context.state.year);
            },
          },
        ]}
        options={{
          pageSize: 20,
          pageSizeOptions: [20, 50, 200],
          emptyRowsWhenPaging: false,
          actionsColumnIndex: -1,
        }}
        icons={tableIcons}
        localization={{
          toolbar: { searchPlaceholder: "Zoeken" },
        }}
      />
      <div className={classes.buttons}>
        <Fab
          color="primary"
          aria-label="add"
          className={classes.addrenter}
          onClick={() => setEdit("false")}
        >
          <PersonAddIcon />
        </Fab>
        <UploadSjaars />
      </div>
      <EditSjaars id={edit} setId={setEdit} />
    </>
  );
};

export default Sjaars;
