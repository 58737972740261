import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import skadiColors from "../skadiColors";
import {
  Person as AccountIcon,
  Dashboard as DashboardIcon,
  Build as KlussenIcon,
  Report as ReportIcon,
  Assessment as AssessmentIcon,
} from "@material-ui/icons";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import classnames from "classnames";
import { StateContext } from "./index";

// Pages
import Klussen from "./pages/klussen";
import Overview from "./pages/overview/overviewContainer";
import Account from "./pages/account";
import Boetes from "./pages/boetes";
import Meerjarenplan from "./pages/meerjarenplan";

const useStyles = makeStyles((theme) => ({
  layout: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
    backgroundColor: skadiColors.grey3,
  },
  content: {
    width: `calc(100vw - 240px)`,
    position: "relative",
    flexGrow: 1,
  },
  head: {
    zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  main: {
    padding: theme.spacing(3),
    minHeight: "100vh",
  },
  mainShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  appcolor: {
    background: "white",
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const structure = [
  {
    id: 1,
    component: Overview,
    label: "Dashboard",
    claim: "overview",
    access: true,
    link: "/overview",
    icon: <DashboardIcon />,
    position: "top",
  },
  {
    id: 2,
    component: Klussen,
    label: "Klussen",
    claim: "renters",
    access: false,
    link: "/klussen",
    icon: <KlussenIcon />,
    position: "top",
  },
  {
    id: 3,
    component: Boetes,
    label: "boetes",
    claim: "renters",
    access: false,
    link: "/boetes",
    icon: <ReportIcon />,
    position: "top",
  },
  {
    id: 4,
    component: Meerjarenplan,
    label: "Meerjarenplan",
    claim: "renters",
    access: false,
    link: "/meerjarenplan",
    icon: <AssessmentIcon />,
    position: "top",
  },
  {
    id: 5,
    component: Account,
    label: "Account",
    claim: "account",
    access: true,
    link: "/account",
    icon: <AccountIcon />,
    position: "bottom",
  },
];

const Layout = (props) => {
  const classes = useStyles();
  const context = useContext(StateContext);

  return (
    <div className={classes.layout}>
      {props.drawer}
      <div
        className={classnames(classes.content, {
          [classes.mainShift]: context.state.isSidebarOpened,
        })}
      >
        <AppBar
          position="absolute"
          classes={{ root: classes.appcolor }}
          className={classnames(classes.head)}
        >
          <Toolbar className={classes.toolbar}>{props.toolbar}</Toolbar>
        </AppBar>
        <main className={classnames(classes.main)}>
          <div className={classes.fakeToolbar} />
          {props.children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
