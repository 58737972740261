import React, { useContext, useState } from "react";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import { makeStyles } from "@material-ui/core/styles";
import FileUploader from "react-firebase-file-uploader";

import firebase from "firebase";
import { produce } from "immer";
import { StateContext } from "..";

const defaultstate = {
  filenames: null,
  downloadURLs: null,
  isUploading: false,
  uploadProgress: 0,
};

const useStyles = makeStyles((theme) => ({
  fab: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(1),
    justifyContent: "flex-end",
    backgroundColor: "#1E3B71",
    color: "white",
    width: "56px",
    height: "56px",
    padding: "0",
    fontSize: "0.875rem",
    minWidth: "0",
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
    boxSizing: "border-box",
    minHeight: "36px",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "500",
    lineHeight: "1.75",
    borderRadius: "50%",
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: "rgb(21, 41, 79)",
    },
  },
  icon: { width: "100%", display: "inherit", justifyContent: "center" },
}));

const UploadSjaars = () => {
  const classes = useStyles();
  const [state, setState] = useState(defaultstate);

  const context = useContext(StateContext);

  const handleUploadStart = () => {
    const newState = produce(state, (draftstate) => {
      draftstate.isUploading = true;
      draftstate.uploadProgress = 0;
    });
    setState(newState);
  };
  const handleProgress = (progress) => {
    const newState = produce(state, (draftstate) => {
      draftstate.uploadProgress = progress;
    });
    setState(newState);
  };

  const handleUploadError = (error) => {
    const newState = produce(state, (draftstate) => {
      draftstate.isUploading = false;
    });
    setState(newState);
  };

  const handleUploadSuccess = (filename) => {
    console.log(filename);
    firebase
      .storage()
      .ref("sjaars")
      .child(filename)
      .getDownloadURL()
      .then((url) => {
        const newState = produce(state, (draftstate) => {
          draftstate.filenames = filename;
          draftstate.uploadProgress = 100;
          draftstate.isUploading = false;
          draftstate.downloadURLs = url;
        });
        setState(newState);
        fetch(url)
          .then((response) => response.json())
          .then((jsonData) => {
            // jsonData is parsed json object received from url
            addBulkSjaars(jsonData);
          })
          .catch((error) => {
            // handle your errors here
          });
      });
  };

  const addBulkSjaars = (data) => {
    if (data && typeof data === "object") {
      // Delete all Sjaars in collection
      var batch = firebase.firestore().batch();
      firebase
        .firestore()
        .collection("eerstejaars" + context.state.year)
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => {
            batch.delete(doc.ref);
          });
          batch.commit();
        });

      // Add new Sjaars
      var uploadBatch = firebase.firestore().batch();
      Object.keys(data).forEach((docKey) => {
        let sjaarsData = data[docKey];
        const standardData = {
          klussen: 0,
          kookklussen: 0,
          autoAssign: true,
        };
        sjaarsData = { ...sjaarsData, ...standardData };
        var docRef = firebase
          .firestore()
          .collection("eerstejaars" + context.state.year)
          .doc();
        uploadBatch.set(docRef, sjaarsData);
      });
      uploadBatch.commit();
    }
  };

  return (
    <>
      <label className={classes.fab}>
        <span className={classes.icon}>
          <GroupAddIcon />
        </span>
        <FileUploader
          hidden
          accept="application/json"
          storageRef={firebase.storage().ref("sjaars")}
          onUploadStart={handleUploadStart}
          onUploadError={handleUploadError}
          onUploadSuccess={handleUploadSuccess}
          onProgress={handleProgress}
        />
      </label>
    </>
  );
};

export default UploadSjaars;
