import React, { useState, useContext } from "react";
import SkadiModal from "../../../../components/modal/skadiModal";
import SkadiSnackbar from "../../../../components/snackbar";
import firebase from "firebase";
import moment from "moment";
import { StateContext } from "../index";
import { addBatchLog } from "../helpers";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: 420,
    "& div": {
      width: "100%",
    },
    "& .MuiInputAdornment-positionEnd": {
      justifyContent: "flex-end",
    },
  },
  formControl: {
    margin: theme.spacing(2, 0, 1, 0),
    minWidth: 120,
    height: 48,
  },
  checkers: {
    margin: theme.spacing(1, 0, 2, 0),
    minWidth: 120,
    height: 48,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttons: {
    marginTop: "auto",
    paddingTop: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
  },
  applybutton: {
    color: "white",
  },
}));

const AddForm = ({ klus, handleClose, sjaars }) => {
  const classes = useStyles();
  const context = useContext(StateContext);

  console.log("before", sjaars);

  sjaars = sjaars
    .filter((a) => {
      return a.autoAssign === true;
    })
    .sort((a, b) => {
      return a.achternaam.localeCompare(b.achternaam);
    })
    .sort((a, b) => {
      return a.klussen - b.klussen;
    });

  console.log("after", sjaars);

  const [error, setError] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [soort, setSoort] = useState("Handmatig");
  const [r, setKlus] = useState(klus);
  const handleChange = (e, n) => setKlus({ ...r, [n]: e.target.value });

  const handleSoort = (event) => {
    console.log(event);
    setSoort(event.target.value);
    if (event.target.value === "Schoonmaak") {
      setKlus({
        ...r,
        naam: "Schoonmaak",
        van: moment([2018, 11, 11, 11]),
        tot: moment([2018, 11, 11, 14]),
        aantal: 3,
      });
    } else if (event.target.value === "Archiveren") {
      setKlus({
        ...r,
        naam: "Archiveren",
        van: moment([2018, 11, 11, 11]),
        tot: moment([2018, 11, 11, 14]),
        aantal: 2,
      });
    } else {
      setKlus({
        ...r,
        naam: null,
        van: null,
        tot: null,
        aantal: 3,
      });
    }
  };

  const handleSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const saveSjaars = () => {
    if (!r.datum || !r.van || !r.tot || !r.naam || !r.aantal || !r.weging) {
      setOpenError(true);
    } else {
      const date = r.datum.startOf("day");
      const van = moment(
        date.format("LL") + " " + r.van.format("LT"),
        "LLL"
      ).toDate();
      const tot = moment(
        date.format("LL") + " " + r.tot.format("LT"),
        "LLL"
      ).toDate();

      const db = firebase.firestore();
      const ref = db
        .collection("klussen-eerstejaars" + context.state.year)
        .doc();
      const id = ref.id;
      let batch = db.batch();
      batch.set(ref, {
        naam: r.naam,
        datum: date.toDate(),
        van: van,
        tot: tot,
        aantal: Number(r.aantal),
        weging: Number(r.weging),
      });
      let i;
      for (i = 0; i < r.aantal; i++) {
        batch.set(
          db.collection("klussen-indeling" + context.state.year).doc(),
          {
            klus: id,
            eerstejaars: sjaars[i].id,
            voltooid: false,
            boete: false,
            opmerking: null,
          }
        );
        batch.update(
          db.collection("eerstejaars" + context.state.year).doc(sjaars[i].id),
          {
            klussen: firebase.firestore.FieldValue.increment(Number(r.weging)),
          }
        );
        addBatchLog(
          batch,
          sjaars[i].id,
          "add",
          "Toegevoegd aan klus: " + r.naam,
          false,
          id,
          r.naam,
          context.state.year
        );
      }
      batch.commit().then(() => {
        handleClose();
        context.actions.setKlus({ id: id, naam: r.naam });
      });
    }
  };
  return (
    <>
      <SkadiModal
        open={true}
        onClose={handleClose}
        title={"Klus toevoegen"}
        onEnter={saveSjaars}
        error={error}
        setError={setError}
      >
        <div className={classes.root}>
          <form className={classes.form}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} lg={12}>
                <FormControl
                  variant="outlined"
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormLabel component="legend">Soort Klus</FormLabel>
                  <RadioGroup
                    aria-label="soort"
                    name="soort"
                    value={soort}
                    onChange={(e) => handleSoort(e)}
                    row
                  >
                    <FormControlLabel
                      value="Handmatig"
                      control={<Radio />}
                      label="Handmatig"
                    />
                    <FormControlLabel
                      value="Schoonmaak"
                      control={<Radio />}
                      label="Schoonmaak"
                    />
                    <FormControlLabel
                      value="Archiveren"
                      control={<Radio />}
                      label="Archiveren"
                    />
                  </RadioGroup>
                </FormControl>
                <TextField
                  required
                  key={"naam"}
                  label={"Klus"}
                  value={r["naam"] ? r["naam"] : ""}
                  onChange={(e) => handleChange(e, "naam")}
                  margin="normal"
                />
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    required
                    clearable
                    margin="normal"
                    id="date-picker"
                    label="Datum"
                    format="DD/MM/YYYY"
                    placeholder="28/10/1926"
                    value={r["datum"]}
                    onChange={(e) => setKlus({ ...r, datum: e })}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <KeyboardTimePicker
                    required
                    ampm={false}
                    label="Van"
                    value={r.van}
                    onChange={(e) => setKlus({ ...r, van: e })}
                  />
                  <KeyboardTimePicker
                    required
                    ampm={false}
                    label="Tot"
                    value={r.tot}
                    onChange={(e) => setKlus({ ...r, tot: e })}
                  />
                </MuiPickersUtilsProvider>
                <TextField
                  required
                  key={"aantal"}
                  label={"Aantal Klussen"}
                  value={r["aantal"]}
                  onChange={(e) => handleChange(e, "aantal")}
                  margin="normal"
                  type="number"
                />
                <TextField
                  required
                  key={"weging"}
                  label={"Weging van de klus"}
                  value={r["weging"]}
                  onChange={(e) => handleChange(e, "weging")}
                  margin="normal"
                  type="number"
                />
              </Grid>
            </Grid>
          </form>
          <div className={classes.buttons}>
            <Button color="primary" onClick={handleClose}>
              Annuleren
            </Button>
            <Button
              className={classes.applybutton}
              color="primary"
              variant="contained"
              onClick={saveSjaars}
            >
              Opslaan
            </Button>
          </div>{" "}
        </div>
      </SkadiModal>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openError}
        autoHideDuration={6000}
        onClose={handleSnack}
      >
        <SkadiSnackbar
          onClose={handleSnack}
          variant="error"
          message={"Vul alle velden in"}
        />
      </Snackbar>
    </>
  );
};

const AddKlussenEerstejaars = ({ id, setId, sjaars }) => {
  const open = id != null;
  const newKlus = {
    aantal: 3,
    weging: 1,
    datum: null,
    naam: null,
    tot: null,
    van: null,
  };
  const handleClose = () => {
    if (open) setId(null);
  };
  if (!open || !sjaars) return <></>;
  else
    return <AddForm klus={newKlus} handleClose={handleClose} sjaars={sjaars} />;
};

export default AddKlussenEerstejaars;
