import React, { useState, useContext } from "react";
import Moment from "moment";
import "moment/locale/nl";
import {
  Page,
  Text,
  View,
  Font,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { useCollectionData } from "react-firebase-hooks/firestore";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import HelveticaNeue from "./HelveticaNeue-Light.ttf";
import GothamBold from "./GothamBold.ttf";
import skadiColors from "skadiColors";
import firebase from "firebase";
import { StateContext } from "./index";

// Register font
Font.register({ family: "Helvetica Neue", src: HelveticaNeue });
Font.register({ family: "GothamBold", src: GothamBold });
Font.registerHyphenationCallback((word) => [word]);

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  text: {
    margin: 12,
    fontSize: 12,
    textAlign: "left",
    fontFamily: "Helvetica Neue",
    hyphens: "none",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    padding: 15,
    textAlign: "left",
    backgroundColor: skadiColors.primary,
    color: "white",
    fontFamily: "GothamBold",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "left",
    color: "grey",
  },
  table: {
    display: "table",
    width: "100%",
    border: "none",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: { margin: "auto", flexDirection: "row" },
  tableCol: {
    width: "20%",
    border: "none",
  },
  tableCol3: {
    width: "33%",
    border: "none",
  },
  tableCell: {
    textAlign: "left",
    marginTop: 5,
    fontSize: 10,
    fontWeight: "bold",
    hyphens: "none",
  },
  tableCellBold: {
    textAlign: "left",
    marginTop: 15,
    fontSize: 10,
    fontFamily: "GothamBold",
  },
  tableCellHeader: {
    textAlign: "left",
    marginTop: 5,
    fontSize: 15,
    fontFamily: "GothamBold",
  },
});

Moment.locale("nl");

// Create Document Component
const MyDocument = ({ klussen, indeling, sjaars, title, phone, year }) => (
  <Document>
    <Page size="A4" orientation="landscape" style={styles.page}>
      <View style={styles.header}>
        <Text>
          {title} LICHTING {year}
        </Text>
      </View>
      <View style={styles.table}>
        {/* TableHeader */}
        <View style={styles.tableRow}>
          <View style={phone ? styles.tableCol : styles.tableCol3}>
            <Text style={styles.tableCellHeader}>DATUM</Text>
          </View>
          <View style={phone ? styles.tableCol : styles.tableCol3}>
            <Text style={styles.tableCellHeader}>NAAM</Text>
          </View>
          {phone ? (
            <>
              <View style={phone ? styles.tableCol : styles.tableCol3}>
                <Text style={styles.tableCellHeader}>NUMMER</Text>
              </View>
              <View style={phone ? styles.tableCol : styles.tableCol3}>
                <Text style={styles.tableCellHeader}>MAIL</Text>
              </View>
            </>
          ) : (
            <></>
          )}

          <View style={phone ? styles.tableCol : styles.tableCol3}>
            <Text style={styles.tableCellHeader}>KLUS</Text>
          </View>
        </View>
      </View>
      {klussen
        .sort((a, b) => {
          return a.van.seconds - b.van.seconds;
        })
        .map((klus, index) => (
          <>
            <View style={styles.table} key={index}>
              <View style={styles.tableRow}>
                <View style={phone ? styles.tableCol : styles.tableCol3}>
                  <Text style={styles.tableCellBold}>
                    {Moment(klus.datum.seconds * 1000).format("LL")}
                  </Text>
                </View>
                <View style={phone ? styles.tableCol : styles.tableCol3}>
                  <Text style={styles.tableCellBold}>
                    {Moment(klus.van.seconds * 1000).format("LT") +
                      " - " +
                      Moment(klus.tot.seconds * 1000).format("LT")}
                  </Text>
                </View>
                {phone ? (
                  <>
                    <View style={phone ? styles.tableCol : styles.tableCol3}>
                      <Text style={styles.tableCell}></Text>
                    </View>
                    <View style={phone ? styles.tableCol : styles.tableCol3}>
                      <Text style={styles.tableCell}></Text>
                    </View>
                  </>
                ) : (
                  <></>
                )}

                <View style={phone ? styles.tableCol : styles.tableCol3}>
                  <Text style={styles.tableCellBold}>{klus.naam}</Text>
                </View>
              </View>

              {indeling
                .filter((a) => {
                  return a.klus === klus.id;
                })
                .map((r, index) => {
                  const found = sjaars.find((a) => {
                    return a.id === r.eerstejaars;
                  });
                  const value = found
                    ? found.voornaam +
                      " " +
                      (found.tussenvoegsel
                        ? found.tussenvoegsel + " " + found.achternaam
                        : found.achternaam)
                    : " ";
                  return (
                    <View style={styles.tableRow} key={index}>
                      <View style={phone ? styles.tableCol : styles.tableCol3}>
                        <Text style={styles.tableCell}></Text>
                      </View>
                      <View style={phone ? styles.tableCol : styles.tableCol3}>
                        <Text style={styles.tableCell}>{value}</Text>
                      </View>
                      {phone ? (
                        <>
                          <View
                            style={phone ? styles.tableCol : styles.tableCol3}
                          >
                            <Text style={styles.tableCell}>
                              {found.telefoon}
                            </Text>
                          </View>
                          <View
                            style={phone ? styles.tableCol : styles.tableCol3}
                          >
                            <Text style={styles.tableCell}>
                              {found["e-mail"]}
                            </Text>
                          </View>
                        </>
                      ) : (
                        <></>
                      )}
                      <View style={phone ? styles.tableCol : styles.tableCol3}>
                        <Text style={styles.tableCell}></Text>
                      </View>
                    </View>
                  );
                })}
            </View>
          </>
        ))}
    </Page>
  </Document>
);

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    minWidth: 120,
  },
  button: {
    padding: "16px 16px",
  },
}));

const PdfKlussenEerstejaars = ({ collection, title, months }) => {
  const classes = useStyles();
  const context = useContext(StateContext);

  const [
    indeling,
    loading2,
  ] = useCollectionData(
    firebase
      .firestore()
      .collection(collection + "-indeling" + context.state.year),
    { idField: "id" }
  );

  const [sjaars, loading3] = useCollectionData(
    firebase.firestore().collection("eerstejaars" + context.state.year),
    { idField: "id" }
  );

  const [from, setFrom] = useState("");
  const [klussen, setklussen] = useState("");
  const [loading1, setLoading] = useState("");
  const [error, setError] = useState("");
  const [to, setTo] = useState("");
  const [show, setHide] = useState(false);
  const [phone, setPhone] = useState(false);
  const [mail, setMail] = useState(false);

  const handleChange = (e, from) => {
    setTo(e.target.value);
    firebase
      .firestore()
      .collection(collection + "-eerstejaars" + context.state.year)
      .where("datum", ">=", new Date(Number(from)))
      .where("datum", "<=", new Date(Number(e.target.value)))
      .get()
      .then(
        (snapshot) => {
          const klussen = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            data["id"] = doc.id;
            klussen.push(data);
          });
          setklussen(klussen);
          setLoading(false);
          setHide(true);
        },
        (err) => {
          setError(err);
        }
      );
  };

  const leftJoin = (objArr1, objArr2, key1, key2) =>
    objArr1.map((anObj1) => ({
      ...objArr2.find((anObj2) => anObj1[key1] === anObj2[key2]),
      ...anObj1,
    }));

  // if (klussen && indeling) {
  //   console.table(
  //     leftJoin(
  //       leftJoin(indeling, klussen, "klus", "id"),
  //       sjaars,
  //       "eerstejaars",
  //       "id"
  //     ).map((obj, i) => ({
  //       ...obj,
  //       van: Moment(obj.van * 1000).format("LT"),
  //       tot: Moment(obj.tot * 1000).format("LT"),
  //       datum: Moment(obj.datum.seconds * 1000).format("LL"),
  //     }))
  //   );
  // }

  if (loading1 || loading2 || loading3 || error) {
    return <></>;
  }
  return (
    <div className="container">
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="outlined-age-simple">Van</InputLabel>
        <Select
          value={from}
          onChange={(e) => setFrom(e.target.value)}
          labelWidth={29}
          inputProps={{
            name: "age",
            id: "outlined-age-simple",
          }}
        >
          <MenuItem value=""></MenuItem>
          {months.map((year, index) => {
            return (
              <MenuItem key={index} value={year.value}>
                {year.text}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="outlined-age-simple">Tot</InputLabel>
        <Select
          value={to}
          onChange={(e) => handleChange(e, from)}
          labelWidth={29}
          inputProps={{
            name: "age",
            id: "outlined-age-simple",
          }}
        >
          <MenuItem value=""></MenuItem>
          {months.map((year, index) => {
            return (
              <MenuItem key={index} value={year.value}>
                {year.text}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={phone}
              onChange={(event) => setPhone(event.target.checked)}
              name="phone"
              color="primary"
            />
          }
          label="Telefoon & mail"
        />
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        className={[classes.formControl, classes.button].join(" ")}
        disabled={!show}
      >
        {show && (
          <PDFDownloadLink
            document={
              <MyDocument
                klussen={klussen}
                indeling={indeling}
                sjaars={sjaars}
                title={title}
                phone={phone}
                year={
                  context.state.year
                    ? context.state.year.split("-").pop()
                    : "2019"
                }
              />
            }
            style={{
              textDecoration: "none",
              color: "white",
            }}
            fileName={
              collection +
              "-" +
              from.format("MMMM-YYYY") +
              "-" +
              to.format("MMMM-YYYY") +
              ".pdf"
            }
          >
            {({ blob, url, loading, error }) =>
              loading ? "Laden document..." : "Genereer Pdf"
            }
          </PDFDownloadLink>
        )}
        {!show && "Selecteer Rapportinterval"}
      </Button>
    </div>
  );
};

export default PdfKlussenEerstejaars;
