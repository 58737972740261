import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3, 2),
  },
}));

const OverviewContainer = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          Eerstejaarsklussen
        </Typography>
        <Paper className={classes.paper}></Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          Kookklussen
        </Typography>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}></Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OverviewContainer;
