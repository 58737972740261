import React, { useState, useEffect } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import firebase from "firebase";

import CommentsList from "./commentsList";
import CommentForm from "./commentForm";

const CommentsComponent = ({ selected }) => {
  const [values, loading, error] = useCollectionData(
    firebase
      .firestore()
      .doc(selected)
      .collection("comments")
      .orderBy("createdAt", "desc"),
    {
      idField: "id",
    }
  );

  const onSubmit = (html) => {
    firebase
      .firestore()
      .doc(selected)
      .collection("comments")
      .doc()
      .set({
        avatarUrl: "test",
        authorUrl: "test",
        fullName: firebase.auth().currentUser.email,
        createdAt: new Date(),
        html: html,
      });
  };

  const onDelete = (comment) => {
    firebase
      .firestore()
      .doc(selected)
      .collection("comments")
      .doc(comment)
      .delete();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Box pl={3} pt={2}>
        <Typography variant="h6" component="div">
          Comments
        </Typography>
      </Box>
      <CommentsList comments={values} onDelete={onDelete} />
      <CommentForm onSubmit={onSubmit} />
    </div>
  );
};

export default CommentsComponent;
