import React from "react";
import Moment from "moment";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { makeStyles } from "@material-ui/core/styles";

import logo from "./brief-hoofd.jpg";
import foot from "./brief-voet.jpg";
import font from "./../klussen/HelveticaNeue-Light.ttf";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "calc(100vh - 62px)",
    marginTop: "-24px",
    marginBottom: "-31px",
  },
}));

// Register font
Font.register({ family: "Helvetica Neue", src: font });

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    display: "grid",
    gridTemplateColumns: "auto",
    alignItems: "center",
  },
  section1: {
    marginTop: 30,
    marginBottom: 0,
    marginLeft: 20,
  },
  underline: {
    fontSize: 11,
    textDecoration: "underline",
    fontFamily: "Helvetica Neue",
  },
  text: {
    fontSize: 11,
    marginTop: 13,
    fontFamily: "Helvetica Neue",
  },
  artikel: {
    fontSize: 11,
    marginTop: 13,
    textDecoration: "underline",
    fontFamily: "Helvetica Neue",
  },
  artikelInhoud: {
    marginTop: 3,
    fontSize: 11,
    fontFamily: "Helvetica Neue",
  },
  section2: {
    marginLeft: 10,
    width: 400,
    padding: 10,
  },
  image: {
    width: 90,
    height: "auto",
  },
  footer: {
    marginTop: 530,
    width: 110,
    height: "auto",
  },
});

const KlusBoete = ({ datum, klus, achternaam, boeteData }) => (
  <>
    <Text style={styles.text}>
      Middels deze brief deel ik u, namens het bestuur der A.R.S.R. “Skadi”,
      mede dat u een boete wordt opgelegd. De reden van deze sanctie is het feit
      dat u op {datum} j.l. niet aanwezig was bij uw {klus}. Voor het niet
      aanwezig zijn bij een sjaarsklus volgt een geldelijke boete van{" "}
      {boeteData.boete_numeriek} euro, zegge: {boeteData.boete_zegge} euro.
    </Text>
    <Text style={styles.text}>
      Deze geldelijke boete van €{boeteData.boete_numeriek},- (zegge{" "}
      {boeteData.boete_zegge}), dient binnen veertien dagen bijgeschreven te
      zijn op het rekeningnummer NL82 RABO 0381839273 ten name van A.R.S.R.
      “Skadi” te Rotterdam met het kenmerk: Sanctie {Moment().format("L")}{" "}
      {achternaam}
    </Text>
  </>
);

const RvoBoete = ({ reden, artikel, artikelInhoud, bedrag, zegge }) => (
  <>
    <Text style={styles.text}>
      Middels deze brief deel ik u, namens het bestuur der A.R.S.R. “Skadi”,
      mede dat u een boete wordt opgelegd. {reden} De boete die u wordt opgelegd
      is gebasseerd op {artikel} van het reglement van orde:
    </Text>
    <Text style={styles.artikel}>{artikel}</Text>
    <Text style={styles.artikelInhoud}>{artikelInhoud}</Text>
    <Text style={styles.text}>
      Deze geldelijke boete van €{bedrag},- (zegge {zegge}), dient binnen
      veertien dagen bijgeschreven te zijn op het rekeningnummer NL82 RABO
      0381839273 ten name van A.R.S.R. “Skadi” te Rotterdam met het kenmerk:
      Sanctie {Moment().format("L")}
    </Text>
  </>
);

// Create Document Component
const MyDocument = ({
  klusboete,
  datum,
  man,
  achternaam,
  bedrag,
  zegge,
  reden,
  artikel,
  artikelInhoud,
  boeteData,
}) => (
  <Document scale={5.0}>
    <Page size="A4" style={styles.page} ruler={false}>
      <View style={styles.section1}>
        <Image style={styles.image} src={logo} />
        <Image style={styles.footer} src={foot} />
      </View>
      <View style={styles.section2}>
        <Text style={styles.underline}>
          Betreft: sanctie bij de A.R.S.R. "Skadi"
        </Text>
        <Text style={styles.text}>Rotterdam, {Moment().format("LL")}, </Text>
        <Text style={styles.text}></Text>
        <Text style={styles.text}>
          Geachte {man ? "heer" : "mejuffrouw"} {achternaam},{" "}
        </Text>
        {klusboete ? (
          <KlusBoete
            datum={datum}
            klus={klusboete}
            achternaam={achternaam}
            boeteData={boeteData}
          />
        ) : (
          <RvoBoete
            reden={reden}
            artikel={artikel}
            artikelInhoud={artikelInhoud}
            bedrag={bedrag}
            zegge={zegge}
          />
        )}
        <Text style={styles.text}>
          Rekenend op uw begrip voor de opgelegde sanctie, en erop vertrouwend u
          hiermee voldoende te hebben geïnformeerd, namens het{" "}
          {boeteData.bestuursjaar}e Bestuur,
        </Text>
        <Text style={styles.text}>Met vriendelijke groet,</Text>
        <Text style={styles.text}>Op de Grote Godin! </Text>
        {klusboete === "kookklus" ? (
          <>
            <Text style={styles.text}>{boeteData.consumabel}</Text>
            <Text style={styles.text}>
              Commissaris van het Consumabel der A.R.S.R. “Skadi”
            </Text>
          </>
        ) : (
          <>
            <Text style={styles.text}>{boeteData.secretaris} </Text>
            <Text style={styles.text}>Secretaris der A.R.S.R. “Skadi”</Text>
          </>
        )}
      </View>
    </Page>
  </Document>
);

export const GenerateKlusBoete = ({
  type,
  datum,
  achternaam,
  geslacht,
  boeteData,
}) => {
  const klus = type === "klussen" ? "eerstejaarsklus" : "kookklus";

  return (
    <MyDocument
      klusboete={klus}
      datum={datum}
      man={geslacht === "Man"}
      achternaam={achternaam}
      boeteData={boeteData}
    />
  );
};

const PdfBoeteComponent = ({ values }) => {
  const classes = useStyles();

  const [data, loading, error] = useDocumentData(
    firebase
      .firestore()
      .collection("boetes")
      .doc("data")
  );

  return (
    <>
      {error && <strong>Error: {JSON.stringify(error)}</strong>}
      {loading && <span>Document: Loading...</span>}
      {data && (
        <PDFViewer width={"100%"} className={classes.container}>
          <MyDocument
            klusboete={null}
            datum={Moment().format("LL")}
            man={values.geslacht === "Man"}
            voornaam={values.voornaam}
            achternaam={values.naam}
            bedrag={values.bedrag}
            zegge={values.zegge}
            reden={
              values.reden
                ? values.reden
                : "u op 15 oktober j.l. niet aanwezig was bij uw sjaarsklus. Voor het niet aanwezig zijn bij een sjaarsklus volgt een geldelijke boete van 50 euro, zegge: vijftig euro."
            }
            artikel={values.artikel ? values.artikel : "Artikel 4 lid 6"}
            artikelInhoud={
              values.artikelInhoud
                ? values.artikelInhoud
                : "Indien een vaarverbod van kracht is, is het verboden het water op te gaan. Het negeren van een door het bestuur afgekondigd vaarverbod wordt bestraft met een boete van €25,- (zegge vijfentwintig) voor iedere betrokken roeier en/of stuurman/vrouw alsmede waar toepasselijk een schadevergoedingsmaatregel."
            }
            boeteData={data}
          />
        </PDFViewer>
      )}
    </>
  );
};

export default PdfBoeteComponent;
