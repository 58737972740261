import React, { useContext, useState, useEffect, useCallback } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import MaterialTable, { MTableToolbar } from "material-table";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

import { MeerjarenContext } from "./index";
import firebase from "firebase";
import { deleteAtPath } from "./../../../components/functionHelpers";
import { ErrorBarTimeout } from "./../../../components/snackbar";
import { tableIcons } from "./../klussen/klussen-eerstejaars/klussenEerstejaars";

const useStyles = makeStyles((theme) => ({
  innerTable: {
    minHeight: "20px !important",
    padding: 0,
    "& button": {
      padding: 6,
    },
  },
  textField: {
    minWidth: "100%",
  },
}));

const compare = (a, b) => {
  // Use toUpperCase() to ignore character casing
  const periodeA = a.periode.toUpperCase();
  const periodeB = b.periode.toUpperCase();

  let comparison = 0;
  if (periodeA > periodeB) {
    comparison = 1;
  } else if (periodeA < periodeB) {
    comparison = -1;
  }
  return comparison;
};

const GetData = ({ selected, id }) => {
  const classes = useStyles();
  const [values, setValues] = useState([]);
  const fetchUrl = useCallback(() => {
    let data = [];
    firebase
      .firestore()
      .doc(selected + "/meetinstrument/" + id)
      .collection("data")
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          // doc.data() is never undefined for query doc snapshots
          const singleData = doc.data();
          singleData.id = doc.id;
          data.push(singleData);
        });
        data = data.sort(compare);
        setValues(data);
      });
  }, [id, selected]);

  useEffect(() => {
    fetchUrl();
    return () => {};
  }, [fetchUrl]);
  JSON.stringify(values);
  if (!values) return "loading...";
  return (
    <Box px={2} py={1} bgcolor="grey.200">
      <MaterialTable
        style={{
          boxShadow: "0px 0px 0px rgba(0,0,0,0.0)",
          backgroundColor: "transparent",
        }}
        title="Data"
        isLoading={values === []}
        onRowClick={(event, rowData) => console.log(rowData)}
        columns={[
          { title: "Periode", field: "periode" },
          {
            title: "Waarde",
            field: "waarde",
          },
        ]}
        data={values}
        components={{
          Toolbar: (props) => (
            <MTableToolbar {...props} classes={{ root: classes.innerTable }} />
          ),
        }}
        options={{
          search: false,
          sorting: false,
          // toolbarButtonAlignment: "left",
          paging: false,
          padding: "dense",
          headerStyle: {
            backgroundColor: "transparent",
            height: "14px",
            padding: "0px 0px 0px 4px",
          },
          pageSize: 5,
          pageSizeOptions: [5, 10],
          emptyRowsWhenPaging: false,
        }}
        icons={tableIcons}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              firebase
                .firestore()
                .doc(selected + "/meetinstrument/" + id)
                .collection("data")
                .add(newData)
                .then(() => {
                  fetchUrl();
                  resolve();
                })
                .catch(function(error) {
                  console.error("Error adding document: ", error);
                  resolve();
                });
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              firebase
                .firestore()
                .doc(selected + "/meetinstrument/" + id)
                .collection("data")
                .doc(oldData.id)
                .update(newData)
                .then(() => {
                  fetchUrl();
                  resolve();
                })
                .catch(function(error) {
                  console.error("Error updating document: ", error);
                  resolve();
                });
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              firebase
                .firestore()
                .doc(selected + "/meetinstrument/" + id)
                .collection("data")
                .doc(oldData.id)
                .delete()
                .then(() => {
                  fetchUrl();
                  resolve();
                })
                .catch(function(error) {
                  console.error("Error removing document: ", error);
                  resolve();
                });
            }),
        }}
        localization={{
          toolbar: { searchPlaceholder: "Zoeken" },
          body: {
            addTooltip: "Toevoegen",
          },
        }}
      />
    </Box>
  );
};

const TableComponent = ({ value }) => {
  const context = useContext(MeerjarenContext);

  const [tableValue, setTableValue] = useState([]);

  useEffect(() => {
    const tableValues = [];
    value.forEach((doc) => {
      tableValues.push({
        naam: doc.naam,
        hoe: doc.hoe,
        doel: doc.doel,
        id: doc.id,
      });
    });
    setTableValue(tableValues);
    return () => {};
  }, [value]);

  return (
    <>
      <MaterialTable
        style={{
          boxShadow: "0px 0px 0px rgba(0,0,0,0.0)",
          backgroundColor: "transparent",
        }}
        title="Meetinstrumenten"
        isLoading={value === []}
        onRowClick={(event, rowData) => console.log(rowData)}
        columns={[
          { title: "Naam", field: "naam" },
          {
            title: "Hoe",
            field: "hoe",
            editComponent: (props) => (
              <TextField
                multiline
                maxRows={4}
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
              />
            ),
          },
          { title: "Doel", field: "doel" },
        ]}
        data={tableValue}
        options={{
          headerStyle: {
            backgroundColor: "transparent",
            padding: "14px 16px 14px 16px",
          },
          pageSize: 5,
          pageSizeOptions: [5, 10],
          emptyRowsWhenPaging: false,
        }}
        icons={tableIcons}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              newData.parent = firebase.firestore().doc(context.state.selected);
              firebase
                .firestore()
                .doc(context.state.selected)
                .collection("meetinstrument")
                .add(newData)
                .then(() => {
                  resolve();
                })
                .catch(function(error) {
                  console.error("Error adding document: ", error);
                  resolve();
                });
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              delete newData.id;
              firebase
                .firestore()
                .doc(context.state.selected)
                .collection("meetinstrument")
                .doc(oldData.id)
                .update(newData)
                .then(() => resolve())
                .catch(function(error) {
                  console.error("Error removing document: ", error);
                  resolve();
                });
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              deleteAtPath(
                context.state.selected + "/meetinstrument/" + oldData.id
              )
                .then(() => {
                  resolve();
                })
                .catch(function(error) {
                  console.error("Error removing document: ", error);
                  resolve();
                });
            }),
        }}
        localization={{
          toolbar: { searchPlaceholder: "Zoeken" },
          body: {
            addTooltip: "Meetinstrument Toevoegen",
          },
        }}
        detailPanel={(rowData) => {
          return <GetData selected={context.state.selected} id={rowData.id} />;
        }}
      />
    </>
  );
};

const ProjectComponent = () => {
  const context = useContext(MeerjarenContext);

  const [value, loading, error] = useCollectionData(
    firebase
      .firestore()
      .doc(context.state.selected)
      .collection("meetinstrument"),
    {
      idField: "id",
    }
  );

  if (error) {
    return <ErrorBarTimeout error={error} />;
  }

  return !loading ? <TableComponent value={value} /> : null;
};

export default ProjectComponent;
