const skadiColors = {
  primary: "#1E3B71", //skadi blue
  secondary: "#BD850D", //skadi gold
  warn: "#ef7963", //red
  info: "#FFD300", //yellow
  grey1: "#262b2c",
  grey2: "#74787b",
  grey3: "#ebebeb",
  grey4: "#383838",
  grey5: "#fbfbfb",
  green: "#04FE80",
};

export default skadiColors;
