import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import skadiTheme from "./skadiTheme";

import Firebase, { FirebaseContext } from "./components/firebase";
import { withAuthentication, AuthUserContext } from "components/session";

import Dashboard from "./dashboard";
import Login from "login";

import * as serviceWorker from "./serviceWorker";

const SwitchViewBase = ({
  authUser,
  location,
  history,
  redirect,
  setRedirect
}) => {
  if (authUser === false) {
    if (location.pathname !== "/login") {
      setRedirect(location.pathname);
      if (redirect === false) history.push("/login");
    } else if (redirect === false) setRedirect("/");
    return <Login redirect={redirect} />;
  } else if (typeof authUser === "object" && authUser !== null)
    return <Dashboard />;
  else return <></>;
};
const SwitchView = withRouter(SwitchViewBase);

const App = res => {
  const authUser = useContext(AuthUserContext);
  const [redirect, setRedirect] = useState(false);

  return (
    <ThemeProvider theme={skadiTheme}>
      <BrowserRouter>
        <SwitchView
          authUser={authUser}
          redirect={redirect}
          setRedirect={setRedirect}
        />
      </BrowserRouter>
    </ThemeProvider>
  );
};

const AuthApp = withAuthentication(App);

ReactDOM.render(
  <FirebaseContext.Provider value={Firebase}>
    <CssBaseline />
    <AuthApp />
  </FirebaseContext.Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
