import { useState, useEffect } from "react";
import firebase from "firebase";

const db = firebase.firestore();

export const addLog = (id, event, content, kook, klus, naam, year) => {
  db.collection("eerstejaars" + year)
    .doc(id)
    .collection("log")
    .doc()
    .set({
      type: event,
      kook: kook ? kook : false,
      klus: klus,
      naam: naam,
      content: content,
      created: new Date(),
    });
};

export const addBatchLog = (
  batch,
  id,
  event,
  content,
  kook,
  klus,
  naam,
  year
) => {
  batch.set(
    db
      .collection("eerstejaars" + year)
      .doc(id)
      .collection("log")
      .doc(),
    {
      type: event,
      kook: kook ? kook : false,
      klus: klus,
      naam: naam,
      content: content,
      created: new Date(),
    }
  );
};

export const incrementSjaars = (id, field, amount, year) => {
  const update = {};
  update[field] = firebase.firestore.FieldValue.increment(amount);
  db.collection("eerstejaars" + year)
    .doc(id)
    .update(update);
};

export const GetSubCollection = (id, year) => {
  // initialize our default state
  const [log, setLog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  // when the id attribute changes (including mount)
  // subscribe to the recipe document and update
  // our state when it changes
  useEffect(() => {
    db.collection("eerstejaars" + year)
      .doc(id)
      .collection("log")
      .get()
      .then(
        (snapshot) => {
          const logs = [];
          snapshot.forEach((doc) => {
            logs.push(doc.data());
          });
          setLog(logs);
          setLoading(false);
        },
        (err) => {
          setError(err);
        }
      );
    return () => {};
  }, [id, year]);
  return [log, loading, error];
};

export const GetKlussen = ({ klusnaam, indeling }) => {
  // initialize our default state
  const [klus, setKlussen] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  // when the id attribute changes (including mount)
  // subscribe to the recipe document and update
  // our state when it changes
  useEffect(() => {
    const unsubscribe = db
      .collection(klusnaam + "-eerstejaars")
      .get()
      .then(
        (snapshot) => {
          const klussen = [];
          snapshot.forEach((doc) => {
            const found = indeling.filter((s) => s.klus === doc.id);
            const voltooid = found.filter((item) => item.voltooid === true)
              .length;
            const boete = found.filter((item) => item.boete === true).length;
            const obj = { voltooid: voltooid, boete: boete };
            klussen.push({ ...doc.data(), ...obj });
          });
          setKlussen(klussen);
          setLoading(false);
        },
        (err) => {
          setError(err);
        }
      );
    return () => unsubscribe;
  }, [klusnaam, indeling]);
  return [klus, loading, error];
};
