import React, { useState, useEffect, forwardRef, useContext } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";

import Moment from "moment";

import { StateContext } from "../index";
import EditKlussenIndeling from "./editKlussenIndeling";
import ChangeKlussenIndeling from "./changeKlussenIndeling";
import { addBatchLog } from "../helpers";
import { GenerateKlusBoete } from "../../boetes/pdfBoete";

import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Edit from "@material-ui/icons/Edit";
import Autorenew from "@material-ui/icons/Autorenew";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";

import firebase from "firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    margin: theme.spacing(1),
    justifyContent: "flex-end",
  },

  addrenter: {
    color: "white",
    margin: theme.spacing(1),
  },
  icon: {
    marginLeft: "-12px",
    color: "black",
  },
}));

const deleteKlussenIndeling = (row, selected, type, year) => {
  const db = firebase.firestore();
  const ref = db.collection(type + "-indeling" + year).doc(row.id);
  let batch = db.batch();
  batch.delete(ref);
  if (row.sjaars) {
    addBatchLog(
      batch,
      row.data.eerstejaars,
      "delete",
      "Verwijdert van klus: " + selected.naam,
      type !== "klussen",
      row.data.klus,
      selected.naam,
      year
    );
    batch.update(
      db.collection("eerstejaars" + year).doc(row.data.eerstejaars),
      type === "klussen"
        ? {
            klussen: firebase.firestore.FieldValue.increment(
              Number(selected.weging) * -1
            ),
          }
        : { kookklussen: firebase.firestore.FieldValue.increment(-1) }
    );
  }
  batch.update(db.collection(type + "-eerstejaars" + year).doc(row.data.klus), {
    aantal: firebase.firestore.FieldValue.increment(-1),
  });
  batch.commit();
};

const KlussenIndeling = ({
  sjaars,
  indeling,
  klussenEerstejaars,
  type,
  selected,
  setSelected,
}) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(null);
  const [change, setChange] = useState(null);
  const [error] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [klussen, setKlussen] = React.useState([]);

  const context = useContext(StateContext);

  const completeAll = (klussen) => {
    let batch = firebase.firestore().batch();
    klussen.forEach((klus) => {
      batch.update(
        firebase
          .firestore()
          .collection(type + "-indeling" + context.state.year)
          .doc(klus.id),
        {
          voltooid: true,
        }
      );
    });
    batch.commit().then(() => {
      setSelected({ id: selected.id, naam: selected.naam });
    });
  };

  useEffect(() => {
    const klussen = [];
    indeling
      .filter((item) => item.klus === selected.id)
      .forEach((doc) => {
        const found = sjaars.find((s) => s.id === doc.eerstejaars);
        klussen.push({ id: doc.id, sjaars: found, data: doc });
      });
    setLoading(false);
    setKlussen(klussen);
    return () => {};
  }, [sjaars, indeling, selected]);

  const [boeteData, boeteLoading, boeteError] = useDocumentData(
    firebase
      .firestore()
      .collection("boetes")
      .doc("data")
  );

  if (error) return error;
  return (
    <>
      <MaterialTable
        isLoading={loading}
        title={"Indeling: " + selected.naam}
        onRowClick={() => {}}
        columns={[
          {
            title: "Sjaars",
            field: "sjaars.voornaam",
            render: (row) =>
              row.sjaars
                ? row.sjaars.voornaam +
                  " " +
                  (row.sjaars.tussenvoegsel
                    ? row.sjaars.tussenvoegsel + " " + row.sjaars.achternaam
                    : row.sjaars.achternaam) +
                  " (" +
                  row.sjaars["e-mail"] +
                  ")"
                : "-",
          },
          {
            title: "Voltooid",
            field: "data.voltooid",
            type: "boolean",
          },
          {
            cellStyle: {
              padding: "0px 14px",
            },
            title: "Boete",
            field: "data.boete",
            render: (row) =>
              row.data.boete && boeteData ? (
                <PDFDownloadLink
                  document={
                    <GenerateKlusBoete
                      type={type}
                      datum={Moment(selected.datum.seconds * 1000).format("LL")}
                      achternaam={row.sjaars.achternaam}
                      geslacht={row.sjaars.geslacht}
                      boeteData={boeteData}
                    />
                  }
                  fileName={
                    "Sanctie " +
                    row.sjaars.achternaam +
                    " " +
                    Moment(selected.datum.seconds * 1000).format("LL") +
                    ".pdf"
                  }
                >
                  {({ blob, url, loading, error }) => {
                    return (
                      <IconButton className={classes.icon}>
                        <CloudDownload />
                      </IconButton>
                    );
                  }}
                </PDFDownloadLink>
              ) : (
                <Remove />
              ),
          },
        ]}
        data={klussen}
        actions={[
          {
            icon: () => <Autorenew />,
            tooltip: "Ruil Klus",
            onClick: (event, rowData) => setChange(rowData),
          },
          {
            icon: () => <Edit />,
            tooltip: "Bewerk Klus",
            onClick: (event, rowData) => setEdit(rowData),
          },
          {
            icon: () => <DeleteOutline />,
            tooltip: "Verwijder Klus",
            onClick: (event, rowData) => {
              if (
                window.confirm(
                  "Weet je zeker dat je deze klus wilt verwijderen? (dit kan niet ongedaan gemaakt worden)"
                )
              )
                deleteKlussenIndeling(
                  rowData,
                  selected,
                  type,
                  context.state.year
                );
            },
          },
        ]}
        options={{
          pageSize: 20,
          pageSizeOptions: [20, 50, 200],
          emptyRowsWhenPaging: false,
          actionsColumnIndex: -1,
        }}
        icons={tableIcons}
        localization={{
          toolbar: { searchPlaceholder: "Zoeken" },
        }}
      />
      <div className={classes.buttons}>
        <Fab
          color="primary"
          aria-label="voltooi"
          className={classes.addrenter}
          onClick={() => completeAll(klussen)}
        >
          <Check />
        </Fab>
        <Fab
          color="primary"
          aria-label="add"
          className={classes.addrenter}
          onClick={() =>
            setEdit({
              data: {
                eerstejaars: null,
                klus: selected.id,
                voltooid: false,
                boete: false,
                opmerking: null,
              },
              id: null,
              sjaars: null,
            })
          }
        >
          <AddIcon />
        </Fab>
      </div>
      <EditKlussenIndeling
        klus={edit}
        setId={setEdit}
        sjaars={sjaars}
        type={type}
        selected={selected}
        setSelected={setSelected}
      />
      <ChangeKlussenIndeling
        klus={change}
        setId={setChange}
        sjaars={sjaars}
        klussenEerstejaars={klussenEerstejaars}
        type={type}
        selected={selected}
        setSelected={setSelected}
      />
    </>
  );
};

export default KlussenIndeling;
