import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import SignOutButton from "../toolbar/signout";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import {
  ArrowBack,
  ArrowBack as ArrowBackIcon,
  Menu as MenuIcon
  // Person as AccountIcon
} from "@material-ui/icons";
import { List, Drawer, IconButton } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { StateContext } from "../index.js";
import skadiColors from "../../skadiColors";
import DrawerLink from "./drawerLink";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  sidebar: {
    zIndex: theme.zIndex.drawer,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxShadow: theme.shadows[4],
    [theme.breakpoints.down("sm")]: {
      boxShadow: "None"
    }
  },
  sidebarPaper: {
    backgroundColor: skadiColors.grey1,
    border: "None",
    color: "white",
    overflowX: "hidden"
  },
  sidebarOpen: {
    width: drawerWidth,
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  sidebarClose: {
    transition: theme.transitions.create(["width", "box-shadow"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 20,
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth
    }
  },
  list: {
    height: "100vh"
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  mobileBackButton: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(0.625)
    },
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  headerMenuButton: {
    marginLeft: theme.spacing(2.5),
    marginTop: theme.spacing(2),
    padding: theme.spacing(1)
  },
  headerIcon: {
    fontSize: 24
  },
  signout: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    "& div": {
      width: "70%"
    }
  }
}));

const DrawerContainer = ({ structure, claim, ...props }) => {
  const classes = useStyles();
  const context = useContext(StateContext);

  useEffect(() => {
    const handlePermanent = () => {
      const windowWidth = window.innerWidth;
      const breakpointWidth = props.theme.breakpoints.values.md;
      const isSmallScreen = windowWidth < breakpointWidth;
      if (isSmallScreen && context.state.isPermanent) {
        context.actions.togglePermanent(false);
      } else if (!isSmallScreen && !context.state.isPermanent) {
        context.actions.togglePermanent(true);
      }
    };
    window.addEventListener("resize", handlePermanent);
    handlePermanent();
    return () => {
      window.removeEventListener("resize", handlePermanent);
    };
  });

  return (
    <Drawer
      variant={context.state.isPermanent ? "permanent" : "temporary"}
      className={classnames(classes.sidebar, {
        [classes.sidebarOpen]: context.state.isSidebarOpened,
        [classes.sidebarClose]: !context.state.isSidebarOpened
      })}
      classes={{
        paper: classnames(classes.sidebarPaper, {
          [classes.sidebarOpen]: context.state.isSidebarOpened,
          [classes.sidebarClose]: !context.state.isSidebarOpened
        })
      }}
      open={context.state.isSidebarOpened}
    >
      <div className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => context.actions.toggleSidebar()}
          className={classes.headerMenuButton}
        >
          {context.state.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classes.headerIcon
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classnames(classes.headerIcon, classes.headerIconCollapse)
              }}
            />
          )}
        </IconButton>
      </div>
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => context.actions.toggleSidebar()}>
          <ArrowBack
            classes={{
              root: classnames(classes.headerIcon, classes.headerIconCollapse)
            }}
          />
        </IconButton>
      </div>
      <List className={classes.list}>
        {structure.map(
          link => (
            // claim[link.claim] ? (
            <DrawerLink
              key={link.id}
              location={props.location}
              isSidebarOpened={context.state.isSidebarOpened}
              isPermanent={context.state.isPermanent}
              {...link}
            />
          )
          // ) : null
        )}

        <Box className={classes.signout}>
          <SignOutButton display={{ xs: "block", md: "none" }} />
        </Box>
      </List>
    </Drawer>
  );
};

export default withTheme(withRouter(DrawerContainer));
