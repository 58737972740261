import React, { useState, useEffect } from "react";
import { produce } from "immer";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";

import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import DeleteIcon from "@material-ui/icons/Delete";

import firebase from "firebase";
import AddPeriod from "./addPeriod";

const useStyles = makeStyles((theme) => ({
  thickBorder: {
    borderWidth: 3,
  },
  noBorder: {
    border: "none",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const defaultColumns = [{ key: "Doel", name: "Doel" }];

const compare = (a, b) => {
  // Use toUpperCase() to ignore character casing
  const bandA = a.key.toUpperCase();
  const bandB = b.key.toUpperCase();

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
};

const FinanceTableComponent = ({ programma }) => {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState(defaultColumns);
  const [columnName, setColumnName] = useState("");
  const [editRow, setEditRow] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .doc(programma)
      .onSnapshot((snapshot) => {
        let data = [];
        let dataColumns = defaultColumns;
        if (snapshot.data().finance && snapshot.data().finance.length !== 0) {
          data = snapshot.data().finance;
          dataColumns = Object.keys(data[0].data[0]).map((key) => {
            return { key: key, name: key };
          });
        }
        setRows(data);
        setColumns(dataColumns);
      });
    return () => {
      unsubscribe();
    };
  }, [programma]);

  const submitToRemote = (state) => {
    firebase
      .firestore()
      .doc(programma)
      .set(
        {
          finance: state,
        },
        { merge: true }
      )
      .then(() => console.log("Submitted to remote"))
      .catch((err) => console.error("Error updating document: ", err));
  };

  const addInstrument = () => {
    const index = rows.length;
    const newState = produce(rows, (draftstate) => {
      draftstate.push({
        naam: "Instrument " + index,
        data: [
          columns.reduce((acc, cur) => ({ ...acc, [cur.key]: 0 }), {}),
          columns.reduce((acc, cur) => ({ ...acc, [cur.key]: 0 }), {}),
        ],
      });
    });
    setRows(newState);
    setEditRow(index);
  };

  const removeInstrument = (index) => {
    if (
      window.confirm(
        "Weet je zeker dat je dit instrument wilt verwijderen? (dit kan niet ongedaan gemaakt worden)"
      )
    ) {
      const newState = produce(rows, (draftstate) => {
        draftstate.splice(index, 1);
      });
      setRows(newState);
      submitToRemote(newState);
    }
  };

  const addPeriod = (date) => {
    const stringDate =
      date.format("YYYY") + "-" + date.add("years", 1).format("YYYY");
    const newRowState = produce(rows, (draftstate) => {
      draftstate.forEach((row) => {
        row.data.forEach((x) => {
          x[stringDate] = 0;
        });
      });
    });
    const newColumnState = produce(columns, (draftstate) => {
      draftstate.push({
        key: stringDate,
        name: stringDate,
      });
      draftstate.sort(compare);
    });
    setRows(newRowState);
    setColumns(newColumnState);
    submitToRemote(newRowState);
  };

  const handleChange = (event, row, type, key) => {
    const newState = produce(rows, (draftstate) => {
      draftstate[row].data[type][key] = event.target.value;
    });
    setRows(newState);
  };

  const handleNameChange = (event, row) => {
    // const newState = produce(rows, (draftstate) => {
    //   draftstate[row].naam = event.target.value;
    // });
    // setRows(newState);
    setColumnName(event.target.value);
  };

  const handleEdit = (row) => {
    if (editRow === row) {
      setEditRow(null);
      const newState = produce(rows, (draftstate) => {
        draftstate[row].naam = columnName;
      });
      setRows(newState);
      setColumnName("");
      submitToRemote(newState);
    } else {
      setColumnName(rows[row].naam);
      setEditRow(row);
    }
  };

  return (
    <>
      <Box pl={3} py={2} className={classes.container}>
        <Typography variant="h6" component="div">
          Financieel
        </Typography>
        <AddPeriod addPeriod={addPeriod} addInstrument={addInstrument} />
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2} />
            {columns.map((column) => (
              <TableCell key={column.key + "-head"}>{column.name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <React.Fragment key={row.naam + "-omzet"}>
              <TableRow>
                <TableCell className={classes.noBorder}>
                  {editRow !== index ? (
                    row.naam
                  ) : (
                    <TextField
                      value={columnName}
                      onChange={(event) => handleNameChange(event, index)}
                    />
                  )}
                </TableCell>
                <TableCell>Omzet</TableCell>
                {columns.map((column) => (
                  <TableCell key={column.key + "-omzet"}>
                    {editRow !== index ? (
                      row.data[0][column.key]
                    ) : (
                      <TextField
                        type="number"
                        value={row.data[0][column.key]}
                        onChange={(event) =>
                          handleChange(event, index, 0, column.key)
                        }
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow key={row.name + "-kosten"}>
                <TableCell className={classes.thickBorder}>
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={() => handleEdit(index)}
                  >
                    {editRow !== index ? (
                      <EditIcon fontSize="inherit" />
                    ) : (
                      <DoneIcon fontSize="inherit" />
                    )}
                  </IconButton>
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={() => removeInstrument(index)}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </TableCell>
                <TableCell className={classes.thickBorder}>Kosten</TableCell>
                {columns.map((column) => (
                  <TableCell
                    className={classes.thickBorder}
                    key={column.key + "-kosten"}
                  >
                    {editRow !== index ? (
                      row.data[1][column.key]
                    ) : (
                      <TextField
                        value={row.data[1][column.key]}
                        onChange={(event) =>
                          handleChange(event, index, 1, column.key)
                        }
                        type="number"
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </React.Fragment>
          ))}
          <TableRow>
            <TableCell>
              <b>Totaal</b>
            </TableCell>
            <TableCell>
              <b>Omzet</b>
            </TableCell>
            {columns.map((column) => (
              <TableCell key={column.key + "-omzet"}>
                <b>
                  {" "}
                  {rows.reduce(
                    (prev, cur) => Number(cur.data[0][column.key]) + prev,
                    0
                  )}
                </b>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <b>Kosten</b>
            </TableCell>
            {columns.map((column) => (
              <TableCell key={column.key + "-kosten"}>
                <b>
                  {" "}
                  {rows.reduce(
                    (prev, cur) => Number(cur.data[1][column.key]) + prev,
                    0
                  )}
                </b>
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default FinanceTableComponent;
